import React from 'react'
import { Link } from 'react-router-dom';
import Topbar from '../../component/Topbar'
import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';
// import { blogData } from '../../data/data';

import {MdKeyboardArrowRight,FaArrowRight, MdOutlineKeyboardArrowLeft} from "../../assets/icons/icons"

export default function Blog() {
  
    return (
        <>
            {/* <Navbar navClass="nav-light"/> */}
      <Topbar />
      <Navbar />

           
            {/* <div className="relative pt-36">
                <div className="grid grid-cols-1 pb-2 text-center">
                    <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Our Products </h3>

                    <p className="text-slate-400 max-w-xl mx-auto">Explore our wide range of restaurant POS software</p>
                </div>
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div> */}

            <section className="relative pt-36 mt-8 pb-16">
                {/* BLog Section start here */}
        
                    <div className="container relative ">
                            <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                                <div className="lg:col-span-12 md:col-span-6">
                                <div>
                                    {/* <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">Restaurant</h6> */}
                                    <h3 className="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0 text-center">Restaurants Types  </h3>
                                    <p className="text-slate-400  mt-2 text-center">Flavour POS software supports various outlet types to streamline operations across different dining environments.</p>
                                </div>
                                    <div className="grid lg:grid-cols-3 grid-cols-1 gap-[30px] py-16">
                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            {/* <img src={Blog}/> */}
                                            <img src='images/index/restaurant2.jpg' alt="" className=''/>
                    
                                            <div className="content p-6">
                                                <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Fine Dining</a>
                                                <p className="text-slate-400 mt-3 text-justify">Advanced table management, reservations, and complex menu options.</p>
                                                
                                                {/* <div className="mt-4">
                                                    <a href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></a>
                                                </div> */}
                                            </div>
                                        </div>
                    
                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/cafe.jpg' alt="" className=''/>
                    
                                            <div className="content p-6">
                                                <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Cafe & Bakery </a>
                                                <p className="text-slate-400 mt-3 text-justify">Managing inventory, tracking orders for custom cakes, and integrating with loyalty.  </p>
                                                
                                                {/* <div className="mt-4">
                                                    <a href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></a>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/pizza.jpg' alt="" className=''/>
                    
                                            <div className="content p-6">
                                                <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Pizzeria</a>
                                                <p className="text-slate-400 mt-3 text-justify">Streamlines ordering and payment, making each transaction faster and more accurate.</p>
                                                
                                                {/* <div className="mt-4">
                                                    <a href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></a>
                                                </div> */}
                                            </div>
                                        </div>


                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/qsr.jpg' alt="" className=''/>
                    
                                            <div className="content p-6">
                                                <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">QSR</a>
                                                <p className="text-slate-400 mt-3 text-justify">Handle high volumes of transactions quickly, with quick order processing.</p>
                                                
                                                {/* <div className="mt-4">
                                                    <a href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></a>
                                                </div> */}
                                            </div>
                                        </div>
                    
                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/banquet.jpg' alt="" className=''/>
                                            <div className="content p-6">
                                                <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Banquets</a>
                                                <p className="text-slate-400 mt-3 text-justify">Handle high volumes of transactions quickly, with quick order processing.</p>
                                                
                                                {/* <div className="mt-4">
                                                    <a href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></a>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/icecream.jpg' alt="" className=''/>
                                            <div className="content p-6">
                                                <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Icecream Parlour</a>
                                                <p className="text-slate-400 mt-3 text-justify">Managing a range of product options, toppings, and custom orders for customers.  </p>
                                                
                                                {/* <div className="mt-4">
                                                    <a href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></a>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/lounge.jpg' alt="" className=''/>
                                            <div className="content p-6">
                                                <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Lounges</a>
                                                <p className="text-slate-400 mt-3 text-justify">Revolutionizes the guest experience by simplifying order management.</p>
                                                
                                                {/* <div className="mt-4">
                                                    <a href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></a>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/restaurant_chain.jpg' alt="" className=''/>
                                            <div className="content p-6">
                                                <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Restaurant Chain</a>
                                                <p className="text-slate-400 mt-3 text-justify">Table management, menu customization, split bills, and reservation handling.</p>
                                                
                                                {/* <div className="mt-4">
                                                    <a href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></a>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/hotel.jpg' alt="" className=''/>
                                            <div className="content p-6">
                                                <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Hotels</a>
                                                <p className="text-slate-400 mt-3 text-justify">Streamline your operations with inventory management, billing and guest profiles. </p>
                                                
                                                {/* <div className="mt-4">
                                                    <a href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></a>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/dhru.jpg' alt="" className=''/>
                                            <div className="content p-6">
                                                <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Drive-thru</a>
                                                <p className="text-slate-400 mt-3 text-justify">It optimizes the ordering process with speed and precision.</p>
                                                
                                                {/* <div className="mt-4">
                                                    <a href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></a>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/icecream.jpg' alt="" className=''/>
                                            <div className="content p-6">
                                                <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Icecream Parlour</a>
                                                <p className="text-slate-400 mt-3 text-justify">Managing a range of product options, toppings, and custom orders for customers.  </p>
                                                
                                                {/* <div className="mt-4">
                                                    <a href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></a>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/pubs.jpg' alt="" className=''/>
                                            <div className="content p-6">
                                                <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Bars & Pubs</a>
                                                <p className="text-slate-400 mt-3 text-justify">Managing drink orders, tabs, and integrating with inventory for alcohol/mixers.                        </p>
                                                
                                                {/* <div className="mt-4">
                                                    <a href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></a>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/Cloud_Kitchen.jpg' alt="" className=''/>
                                            <div className="content p-6">
                                                <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Cloud Kitchen</a>
                                                <p className="text-slate-400 mt-3 text-justify">Optimize kitchen workflows, track performance and quickly adapt to changing demands. </p>
                                                
                                                {/* <div className="mt-4">
                                                    <a href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></a>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/food.jpg' alt="" className=''/>
                                            <div className="content p-6">
                                                <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Food Court</a>
                                                <p className="text-slate-400 mt-3 text-justify">Simplifies and speeds up service with its user-friendly interface and order management. </p>
                                                
                                                {/* <div className="mt-4">
                                                    <a href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></a>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/catering2.jpg' alt="" className=''/>
                                            <div className="content p-6">
                                                <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Catering</a>
                                                <p className="text-slate-400 mt-3 text-justify">Handle complex catering orders, simplifies invoicing and provides detailed reports.</p>
                                                
                                                {/* <div className="mt-4">
                                                    <a href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></a>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/bakery.avif' alt="" className=''/>
                                            <div className="content p-6">
                                                <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Bakery</a>
                                                <p className="text-slate-400 mt-3 text-justify">Managing inventory, tracking orders for custom cakes, and integrating with loyalty.</p>
                                                
                                                {/* <div className="mt-4">
                                                    <a href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></a>
                                                </div> */}
                                            </div>
                                        </div>


                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/food_court2.jpg' alt="" className=''/>
                                            <div className="content p-6">
                                                <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Food Carts</a>
                                                <p className="text-slate-400 mt-3 text-justify">Simplifies order processing and payment handling on the go wit quick transactions.</p>
                                                
                                                {/* <div className="mt-4">
                                                    <a href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></a>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/club.jpeg' alt="" className=''/>
                                            <div className="content p-6">
                                                <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Clubs</a>
                                                <p className="text-slate-400 mt-3 text-justify">Elevates the guest experience with its sleek interface designed for fast-paced environments.</p>
                                                
                                                {/* <div className="mt-4">
                                                    <a href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></a>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/franchise2.png' alt="" className=''/>
                                            <div className="content p-6">
                                                <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Franchise Network</a>
                                                <p className="text-slate-400 mt-3 text-justify">Unified solution that enhances consistency and efficiency across all locations.</p>
                                                
                                                {/* <div className="mt-4">
                                                    <a href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></a>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/dhaba.jpg' alt="" className=''/>
                                            <div className="content p-6">
                                                <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Dhabas</a>
                                                <p className="text-slate-400 mt-3 text-justify">Modernizes operations with a user-friendly interface tailored for traditional settings.</p>
                                                
                                                {/* <div className="mt-4">
                                                    <a href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></a>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/multiplex.jpg' alt="" className=''/>
                                            <div className="content p-6">
                                                <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Multiplex/ Cinema</a>
                                                <p className="text-slate-400 mt-3 text-justify">Revolutionizes the movie-going experience with its fast, intuitive system.</p>
                                                
                                                {/* <div className="mt-4">
                                                    <a href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></a>
                                                </div> */}
                                            </div>
                                        </div>

                                        
                                    </div>
                                </div>
                            </div>
                    </div>

                
            </section>
            <Footer />

        </>
    )
}
