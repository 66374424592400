// import React, { useState } from 'react'
// import { Link } from 'react-router-dom';
import axios from "axios";

// import about2 from '../../assets/images/company/about2.png';
// import cta_bg from '../../assets/images/cta-bg.jpg';
// import app from '../../assets/images/app/app.png';
// import playstore from '../../assets/images/app/playstore.png';
// import classic02 from '../../assets/images/app/mobile4.avif';
// import imageP from '../../assets/images/client/05.jpg';
import Navbar from '../../component/Navbar/navbar'

import Topbar from '../../component/Topbar'
import Footer from '../../component/Footer/footer';

// import Blog2 from '../../component/blog2';
import CookieModal from '../../component/cookieModal';
import UserFeedBack from '../../component/userFeedBack';

import "../../../node_modules/react-modal-video/css/modal-video.css";

// import * as Icon from 'react-feather';


import TinySlider from 'tiny-slider-react';

import Swal from 'sweetalert2'

export default function IndexLandingFour() {
    // let [activeIndex, setActiveIndex] = useState(0);


    const Open = () => {
        document.getElementsByClassName('popup')[0].style.display = "block";
        document.getElementsByClassName('popup')[0].style.opacity = "1";

    };


    const Close = () => {
        document.getElementsByClassName('popup')[0].style.display = "none";
        document.getElementsByClassName('popup')[0].style.opacity = "0";

    };



    const handleSubmit = (event) => {
        event.preventDefault();




        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://edukrypt.com/api/sendEmailPoskrypt',
            headers: {
                'Content-Type': 'application/json'
            },
        };

        axios.request(config)
            .then((response) => {

                document.getElementById("create-course-form").reset();
                Swal.fire(response.data.message);



            })
            .catch((error) => {
                console.log(error);
            });


    };

    const settings = {
        container: '.tiny-five-item',
        controls: true,
        mouseDrag: true,

        loop: true,
        rewind: false,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 4000,
        navPosition: "bottom",
        // controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
        nav: false,
        speed: 400,
        gutter: 0,
        responsive: {
            1025: {
                items: 3
            },

            992: {
                items: 4
            },

            767: {
                items: 3
            },

            425: {
                items: 1
            },
        },
    }

    const settings4 = {
        container: '.tiny-five-item',
        controls: true,
        mouseDrag: true,
        loop: true,
        rewind: false,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 4000,
        navPosition: "bottom",
        // controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
        nav: false,
        speed: 400,
        gutter: 0,
        responsive: {
            1025: {
                items: 1
            },

            992: {
                items: 4
            },

            767: {
                items: 3
            },

            425: {
                items: 1
            },
        },
    }


    const settings3 = {
        container: '.tiny-five-item',
        controls: true,
        mouseDrag: true,

        loop: true,
        rewind: false,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 4000,
        navPosition: "bottom",
        controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
        nav: false,
        speed: 400,
        gutter: 0,
        responsive: {
            1025: {
                items: 3
            },

            992: {
                items: 4
            },

            767: {
                items: 3
            },

            425: {
                items: 1
            },
        },
    }



    return (
        <>
            <span className="fixed blur-[200px] size-[600px] rounded-full top-1/2 -translate-y-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 bg-indigo-600/20"></span>
            <Topbar />

            <Navbar />
            <section className="relative table w-full pt-24 pb-12  dark:bg-slate-800 " id="home">
                <div className="container relative">
                    <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px] relative">
                        <div className="md:me-6">
                            {/* <ul className="list-none mb-0 text-amber-400 text-xl space-x-1">
                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                <li className="inline"><i className="mdi mdi-star"></i></li>
                                <li className="inline"><i className="mdi mdi-star"></i></li>
                            </ul> */}
                            <h4 className="font-bold lg:leading-normal leading-normal text-3xl lg:text-5xl mb-5 text-black dark:text-white">
                                {/* <span className="after:absolute after:end-0 after:start-0 after:bottom-3 after:-rotate-6 after:h-2 after:w-auto after:rounded-md after:bg-indigo-600/20 relative text-indigo-600">  </span> */} The  Most  Advanced Restaurant POS </h4>
                            <p className="text-slate-400 max-w-xl text-justify">Effortlessly Manage & Access Restaurant Data, be it for Single Restaurant, Multiple Branches or Franchise Network for all Locations, Ensuring Streamlined Operations & Informed Decision-Making & Strategy at your Fingertips </p>

                            <div className="mt-6">
                                <a class="py-2 px-5  inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md cursor-pointer" onClick={Open}>Book Demo</a>
                                {/* <a class="py-2 px-5  inline-block ms-2 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md" href="/get-started">Purchase</a> */}

                            </div>
                        </div>



                        <div className="relative d-flex justify-content-end">
                            <div className="grid grid-cols-12 gap-4 items-center">
                                <div className="col-span-5">
                                    <div className="grid grid-cols-1 gap-4">
                                        <img src='images/index/43723.jpg' className="shadow rounded-lg" alt="" />

                                        <div className="ms-auto">
                                            <div className=" bg-indigo-600/10 rounded-lg">
                                                <img src='images/index/mobile.png' className="shadow rounded-lg " alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-span-7">
                                    <div className="grid grid-cols-1 gap-4">
                                        <img src='images/index/banner.png' className="shadow rounded-lg" alt="" />
                                    </div>
                                </div>
                            </div>                            <div className="overflow-hidden absolute md:size-[500px] size-[400px] bg-gradient-to-tl to-indigo-600/20 via-indigo-600/70 from-indigo-600 bottom-1/2 translate-y-1/2 md:start-0 start-1/2 ltr:md:translate-x-0 ltr:-translate-x-1/2 rtl:md:translate-x-0 rtl:translate-x-1/2 -z-1 shadow-md shadow-indigo-600/10 rounded-full"></div>
                            <div className="overflow-hidden absolute md:size-[500px] size-[400px] bg-gradient-to-tl to-indigo-600/20 via-indigo-600/70 from-indigo-600 bottom-1/2 translate-y-1/2 md:start-0 start-1/2 ltr:md:translate-x-0 ltr:-translate-x-1/2 rtl:md:translate-x-0 rtl:translate-x-1/2 -z-1 shadow-md shadow-indigo-600/10 rounded-full"></div>


                        </div>

                        <div className="relative animate-[spin_30s_linear_infinite] -z-1">
                            <span className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:h-2 after:w-8 after:rounded-md after:bg-indigo-600/20 relative after:z-10"></span>
                            <span className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:rotate-90 after:h-2 after:w-8 after:rounded-md after:bg-indigo-600/20 relative after:z-10"></span>
                        </div>
                    </div>
                </div>
            </section>

            <div className='container pb-16 mt-16'>
                <div class="grid md:grid-cols-12 grid-cols-1 pb-2 ms-3 items-end">
                    <div class="lg:col-span-8 md:col-span-6 md:text-start text-center">
                            {/* <h6 class="text-indigo-600 font-semibold mb-2">Portfolio</h6> */}
                            <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Our Products</h3>
                            <p class="text-slate-400 max-w-xl">Explore our wide range of restaurant POS software</p>
                        </div>
                        <div class="lg:col-span-4 md:col-span-6 md:text-end hidden md:block">
                            <a class="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500" href="/product">See More <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" class="text-sm ms-1" height="1em" width="1em" xmlns=""><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg></a>
                    </div>
                </div>
                {/* <div className="lg:col-span-8 md:col-span-6 md:text-start text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-center">Our Products    </h3>
                    <p className="text-slate-400  text-justify text-center">Explore our wide range of restaurant POS software</p>
                </div> */}
                <div className="tiny-five-item">
                    <TinySlider settings={settings4}>
                        <div className="tiny-slide pt-16 pb-24" >
                            <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[10px]">
                                <div className="relative">
                                    <div className="relative">
                                        <img src='images/index/pos4.png' className=" width90 rounded-lg  p-2 bg-white radius-12 " alt="" />
                                    </div>
                                    <div className="overflow-hidden absolute md:size-[450px] size-[350px] bg-gradient-to-tr to-indigo-600/20 via-indigo-600/70 from-indigo-600 bottom-1/2 translate-y-1/2 md:end-0 end-1/2 md:translate-x-0 translate-x-1/2 -z-1 shadow-md shadow-indigo-600/10 rounded-full left-10 product_rounded_box"></div>
                                </div>

                                <div className="lg:ms-8">
                                    <h4 className="mb-4 md:text-3xl text-2xl lg:leading-normal leading-normal font-semibold">POS</h4>
                                    <p className="text-slate-400 mb-6 text-justify">Our restaurant POS software streamlines operations with intuitive features designed for efficiency. From order management and inventory tracking to real-time reporting, it enhances both front-of-house and back-of-house performance, Whether for fine dining or quick service, Flavour POS is essential for improving customer experiences.</p>
                                </div>
                            </div>
                        </div>

                        <div className="tiny-slide pt-16 pb-24" >
                            <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[10px]">
                                <div className="relative">
                                    {/* <div className="overflow-hidden absolute md:size-[500px] size-[400px] bg-gradient-to-tr to-indigo-600/20 via-indigo-600/70 from-indigo-600 bottom-1/2 translate-y-1/2 md:end-0 end-1/2 md:translate-x-0 translate-x-1/2 -z-1 shadow-md shadow-indigo-600/10 rounded-full"></div> */}
                                    <div className="relative">
                                        <img src='images/index/kds5.png' className="width90 rounded-lg  p-2 bg-white radius-12 " alt="" />
                                    </div>
                                    <div className="overflow-hidden absolute md:size-[450px] size-[350px] bg-gradient-to-tr to-indigo-600/20 via-indigo-600/70 from-indigo-600 bottom-1/2 translate-y-1/2 md:end-0 end-1/2 md:translate-x-0 translate-x-1/2 -z-1 shadow-md shadow-indigo-600/10 rounded-full left-10 product_rounded_box"></div>
                                </div>

                                <div className="lg:ms-8">
                                    <h4 className="mb-4 md:text-3xl text-2xl lg:leading-normal leading-normal font-semibold">KDS</h4>
                                    <p className="text-slate-400 mb-6 text-justify">Our KDS (Kitchen Display System) software revolutionizes kitchen operations by providing real-time order tracking and streamlined communication between front and back of house. Designed to integrate seamlessly with POS system, our KDS software helps your kitchen staff stay organized and efficient, ultimately improving overall service quality.</p>
                                </div>
                            </div>
                        </div>

                        <div className="tiny-slide pt-16 pb-24" >
                            <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[10px]">
                                <div className="relative">
                                    <div className="relative">
                                        <img src='images/index/token.png' className="width90 rounded-lg  p-2 bg-white radius-12 " alt="" />
                                    </div>
                                    <div className="overflow-hidden absolute md:size-[450px] size-[350px] bg-gradient-to-tr to-indigo-600/20 via-indigo-600/70 from-indigo-600 bottom-1/2 translate-y-1/2 md:end-0 end-1/2 md:translate-x-0 translate-x-1/2 -z-1 shadow-md shadow-indigo-600/10 rounded-full left-10 product_rounded_box"></div>

                                    {/* <div className="overflow-hidden absolute md:size-[500px] size-[400px] bg-gradient-to-tr to-indigo-600/20 via-indigo-600/70 from-indigo-600 bottom-1/2 translate-y-1/2 md:end-0 end-1/2 md:translate-x-0 translate-x-1/2 -z-1 shadow-md shadow-indigo-600/10 rounded-full"></div> */}
                                </div>

                                <div className="lg:ms-8">
                                    <h4 className="mb-4 md:text-3xl text-2xl lg:leading-normal leading-normal font-semibold">Token Management</h4>
                                    <p className="text-slate-400 mb-6 text-justify">Our token display software enhances customer experience by providing real-time updates on order status through digital displays. It helps streamline operations by clearly communicating wait times and queue numbers, reducing customer anxiety and improving service efficiency.</p>
                                </div>
                            </div>
                        </div>

                        <div className="tiny-slide pt-16 pb-24" >
                            <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[10px]">
                                <div className="relative">
                                    <div className="relative">
                                        <img src='images/index/menu2.jpg' className="width90 rounded-lg  p-2 bg-white radius-12  " alt="" />
                                    </div>
                                    <div className="overflow-hidden absolute md:size-[450px] size-[350px] bg-gradient-to-tr to-indigo-600/20 via-indigo-600/70 from-indigo-600 bottom-1/2 translate-y-1/2 md:end-0 end-1/2 md:translate-x-0 translate-x-1/2 -z-1 shadow-md shadow-indigo-600/10 rounded-full left-10 product_rounded_box"></div>


                                    {/* <div className="overflow-hidden absolute md:size-[500px] size-[400px] bg-gradient-to-tr to-indigo-600/20 via-indigo-600/70 from-indigo-600 bottom-1/2 translate-y-1/2 md:end-0 end-1/2 md:translate-x-0 translate-x-1/2 -z-1 shadow-md shadow-indigo-600/10 rounded-full"></div> */}
                                </div>

                                <div className="lg:ms-8">
                                    <h4 className="mb-4 md:text-3xl text-2xl lg:leading-normal leading-normal font-semibold">QR Menu</h4>
                                    <p className="text-slate-400 mb-6 text-justify">Our restaurant QR menu feature offers a modern, contactless dining experience by allowing customers to view and order from your menu using their smartphones. With easy-to-update digital menus and real-time availability updates, it simplifies the ordering process and enhances customer convenience.</p>
                                </div>
                            </div>
                        </div>

                        <div className="tiny-slide pt-16 pb-24" >
                            <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[10px]">
                                <div className="relative">
                                    <div className="relative">
                                        <img src='images/index/calling3.png' className="width90 rounded-lg  p-2 bg-white radius-12 " alt="" />
                                    </div>
                                    <div className="overflow-hidden absolute md:size-[450px] size-[350px] bg-gradient-to-tr to-indigo-600/20 via-indigo-600/70 from-indigo-600 bottom-1/2 translate-y-1/2 md:end-0 end-1/2 md:translate-x-0 translate-x-1/2 -z-1 shadow-md shadow-indigo-600/10 rounded-full left-10 product_rounded_box"></div>

                                    {/* <div className="overflow-hidden absolute md:size-[500px] size-[400px] bg-gradient-to-tr to-indigo-600/20 via-indigo-600/70 from-indigo-600 bottom-1/2 translate-y-1/2 md:end-0 end-1/2 md:translate-x-0 translate-x-1/2 -z-1 shadow-md shadow-indigo-600/10 rounded-full"></div> */}
                                </div>

                                <div className="lg:ms-8">
                                    <h4 className="mb-4 md:text-3xl text-2xl lg:leading-normal leading-normal font-semibold">QR Calling</h4>
                                    <p className="text-slate-400 mb-6 text-justify">Our restaurant QR calling software transforms customer service by enabling diners to request assistance or place special requests directly from their smartphones using a QR code. This contactless solution improves service efficiency and enhances the dining experience.</p>
                                </div>
                            </div>
                        </div>

                        <div className="tiny-slide pt-16 pb-24">
                            <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[10px]">
                                <div className="relative">
                                    <div className="relative">
                                        <img src='images/index/service4.png' className="width90 rounded-lg   p-2 bg-white radius-12 " alt="" />
                                    </div>
                                    <div className="overflow-hidden absolute md:size-[450px] size-[350px] bg-gradient-to-tr to-indigo-600/20 via-indigo-600/70 from-indigo-600 bottom-1/2 translate-y-1/2 md:end-0 end-1/2 md:translate-x-0 translate-x-1/2 -z-1 shadow-md shadow-indigo-600/10 rounded-full left-10 product_rounded_box"></div>


                                    {/* <div className="overflow-hidden absolute md:size-[500px] size-[400px] bg-gradient-to-tr to-indigo-600/20 via-indigo-600/70 from-indigo-600 bottom-1/2 translate-y-1/2 md:end-0 end-1/2 md:translate-x-0 translate-x-1/2 -z-1 shadow-md shadow-indigo-600/10 rounded-full"></div> */}
                                </div>

                                <div className="lg:ms-8">
                                    <h4 className="mb-4 md:text-3xl text-2xl lg:leading-normal leading-normal font-semibold">Server App  </h4>
                                    <p className="text-slate-400 mb-6 text-justify">Our restaurant captain app enhances service efficiency by equipping front-of-house staff with a powerful tool to manage orders, track table status, and communicate seamlessly with the kitchen which delivers a superior dining experience.</p>
                                </div>
                            </div>
                        </div>

                        <div className="tiny-slide pt-16 pb-24" >
                            <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-16 gap-[10px]">
                                <div className="relative">
                                    <div className="relative ">
                                        <img src='images/index/kiosk.jpg' className="width90 mx-end rounded-lg  p-2 bg-white radius-12  " alt="" />
                                    </div>
                                    <div className="overflow-hidden absolute md:size-[450px] size-[350px] bg-gradient-to-tr to-indigo-600/20 via-indigo-600/70 from-indigo-600 bottom-1/2 translate-y-1/2 md:end-0 end-1/2 md:translate-x-0 translate-x-1/2 -z-1 shadow-md shadow-indigo-600/10 rounded-full left-10 product_rounded_box"></div>

                                    {/* <div className="overflow-hidden absolute md:size-[500px] size-[400px] bg-gradient-to-tr to-indigo-600/20 via-indigo-600/70 from-indigo-600 bottom-1/2 translate-y-1/2 md:end-0 end-1/2 md:translate-x-0 translate-x-1/2 -z-1 shadow-md shadow-indigo-600/10 rounded-full"></div> */}
                                </div>
                                <div className="lg:ms-2">
                                    <h4 className="mb-4 md:text-3xl text-2xl lg:leading-normal leading-normal font-semibold">Kiosk</h4>
                                    <p className="text-slate-400 mb-6 text-justify">Our restaurant kiosk app modernizes the ordering process by allowing customers to place their own orders directly from a self-service kiosk. With an intuitive interface and customizable menu options, it speeds up order processing and reduces wait times.</p>
                                </div>
                            </div>
                        </div>
                    </TinySlider>
                </div>
            </div>



            <section className="relative ">
                <div className="container relative md:mt-24 mt-8">
                    <div class="grid md:grid-cols-12 grid-cols-1 pb-2 ms-3 items-end">
                        <div class="lg:col-span-8 md:col-span-6 md:text-start text-center">
                            {/* <h6 class="text-indigo-600 font-semibold mb-2">Portfolio</h6> */}
                            <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Restaurants Types </h3>
                            <p class="text-slate-400 max-w-xl">Flavour POS software supports various outlet types to streamline operations across different dining environments.</p>
                        </div>
                        <div class="lg:col-span-4 md:col-span-6 md:text-end hidden md:block">
                            <a class="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500" href="/restaurant">See More <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" class="text-sm ms-1" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg></a>
                    </div>
                    </div>
                    <div className="grid md:grid-cols-12 grid-cols-1 pb-8 items-end">


                        {/* <div className="lg:col-span-12 md:col-span-12 md:text-start text-center">
                            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-center">Restaurants Types </h3>
                            <p className="text-slate-400  text-justify text-center">Flavour POS software supports various outlet types to streamline operations across different dining environments.</p>
                        </div> */}

                    </div>

                    <div className="tiny-five-item">
                        <TinySlider settings={settings}>
                            <div className="tiny-slide" >
                                <div className="picture-item p-4 rounded-md" >
                                    <div className="">
                                        <div className="relative">
                                            <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                                                <img src='images/index/restaurant2.jpg' className="rounded-t-md shadow" alt="" />
                                            </div>
                                        </div>

                                        <div className="pt-4 px-3">
                                            <h5 className="mb-1 font-semibold text-lg">Fine Dining</h5>
                                            <span className="text-slate-400 text-justify">Advanced table management, reservations, and complex menu options.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tiny-slide" >
                                <div className="picture-item p-4 rounded-md" >
                                    <div className="">
                                        <div className="relative">
                                            <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                                                <img src='images/index/cafe.jpg' className="rounded-t-md shadow" alt="" />
                                            </div>
                                        </div>

                                        <div className="pt-4 px-3">
                                            <h5 className="mb-1 font-semibold text-lg">Cafe & Bakery    </h5>
                                            <span className="text-slate-400 text-justify">Managing inventory, tracking orders for custom cakes, and integrating with loyalty.  </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tiny-slide" >
                                <div className="picture-item p-4 rounded-md" >
                                    <div className="">
                                        <div className="relative">
                                            <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                                                <img src='images/index/pizza.jpg' className="rounded-t-md shadow" alt="" />
                                            </div>
                                        </div>

                                        <div className="pt-4 px-3">
                                            <h5 className="mb-1 font-semibold text-lg">Pizzeria</h5>
                                            <span className="text-slate-400 text-justify">Streamlines ordering and payment, making each transaction faster and more accurate. </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tiny-slide" >
                                <div className="picture-item p-4 rounded-md" >
                                    <div className="">
                                        <div className="relative">
                                            <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                                                <img src='images/index/qsr.jpg' className="rounded-t-md shadow" alt="" />
                                            </div>
                                        </div>

                                        <div className="pt-4 px-3">
                                            <h5 className="mb-1 font-semibold text-lg">QSR</h5>
                                            <span className="text-slate-400 text-justify">Handle high volumes of transactions quickly, with quick order processing.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tiny-slide" >
                                <div className="picture-item p-4 rounded-md" >
                                    <div className="">
                                        <div className="relative">
                                            <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                                                <img src='images/index/banquet.jpg' className="rounded-t-md shadow" alt="" />
                                            </div>
                                        </div>

                                        <div className="pt-4 px-3">
                                            <h5 className="mb-1 font-semibold text-lg">Banquets</h5>
                                            <span className="text-slate-400 text-justify">Handle high volumes of transactions quickly, with quick order processing.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tiny-slide" >
                                <div className="picture-item p-4 rounded-md" >
                                    <div className="">
                                        <div className="relative">
                                            <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                                                <img src='images/index/icecream.jpg' className="rounded-t-md shadow" alt="" />
                                            </div>
                                        </div>

                                        <div className="pt-4 px-3">
                                            <h5 className="mb-1 font-semibold text-lg">Icecream Parlour</h5>
                                            <span className="text-slate-400 text-justify">Managing a range of product options, toppings, and custom orders for customers.   </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tiny-slide" >
                                <div className="picture-item p-4 rounded-md" >
                                    <div className="">
                                        <div className="relative">
                                            <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                                                <img src='images/index/lounge.jpg' className="rounded-t-md shadow" alt="" />
                                            </div>
                                        </div>

                                        <div className="pt-4 px-3">
                                            <h5 className="mb-1 font-semibold text-lg">Lounges</h5>
                                            <span className="text-slate-400 text-justify">Revolutionizes the guest experience by simplifying order management. </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tiny-slide" >
                                <div className="picture-item p-4 rounded-md" >
                                    <div className="">
                                        <div className="relative">
                                            <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                                                <img src='images/index/restaurant_chain.jpg' className="rounded-t-md shadow" alt="" />
                                            </div>
                                        </div>

                                        <div className="pt-4 px-3">
                                            <h5 className="mb-1 font-semibold text-lg">Restaurant Chain</h5>
                                            <span className="text-slate-400 text-justify">Table management, menu customization, split bills, and reservation handling.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tiny-slide" >
                                <div className="picture-item p-4 rounded-md" >
                                    <div className="">
                                        <div className="relative">
                                            <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                                                <img src='images/index/hotel.jpg' className="rounded-t-md shadow" alt="" />
                                            </div>
                                        </div>

                                        <div className="pt-4 px-3">
                                            <h5 className="mb-1 font-semibold text-lg">Hotels</h5>
                                            <span className="text-slate-400 text-justify">Streamline your operations with inventory management, billing and guest profiles.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tiny-slide" >
                                <div className="picture-item p-4 rounded-md" >
                                    <div className="">
                                        <div className="relative">
                                            <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                                                <img src='images/index/dhru.jpg' className="rounded-t-md shadow" alt="" />
                                            </div>
                                        </div>

                                        <div className="pt-4 px-3">
                                            <h5 className="mb-1 font-semibold text-lg">Drive-thru</h5>
                                            <span className="text-slate-400 text-justify">It optimizes the ordering process with speed and precision.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TinySlider>
                    </div>

                    <div className="tiny-five-item">
                        <TinySlider settings={settings3}>
                            <div className="tiny-slide" >
                                <div className="picture-item p-4 rounded-md" >
                                    <div className="">
                                        <div className="relative">
                                            <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                                                <img src='images/index/pubs.jpg' className="rounded-t-md shadow" alt="" />
                                            </div>
                                        </div>

                                        <div className="pt-4 px-3">
                                            <h5 className="mb-1 font-semibold text-lg">Bars & Pubs</h5>
                                            <span className="text-slate-400 text-justify">Managing drink orders, tabs, and integrating with inventory for alcohol/mixers.                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tiny-slide" >
                                <div className="picture-item p-4 rounded-md" >
                                    <div className="">
                                        <div className="relative">
                                            <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                                                <img src='images/index/Cloud_Kitchen.jpg' className="rounded-t-md shadow" alt="" />
                                            </div>
                                        </div>

                                        <div className="pt-4 px-3">
                                            <h5 className="mb-1 font-semibold text-lg">Cloud Kitchen</h5>
                                            <span className="text-slate-400 text-justify">Optimize kitchen workflows, track performance and quickly adapt to changing demands.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tiny-slide" >
                                <div className="picture-item p-4 rounded-md" >
                                    <div className="">
                                        <div className="relative">
                                            <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                                                <img src='images/index/food.jpg' className="rounded-t-md shadow food_court" alt="" />
                                            </div>
                                        </div>

                                        <div className="pt-4 px-3">
                                            <h5 className="mb-1 font-semibold text-lg">Food Court</h5>
                                            <span className="text-slate-400 text-justify">Simplifies and speeds up service with its user-friendly interface and order management.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tiny-slide" >
                                <div className="picture-item p-4 rounded-md" >
                                    <div className="">
                                        <div className="relative">
                                            <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                                                <img src='images/index/catering2.jpg' className="rounded-t-md shadow" alt="" />
                                            </div>
                                        </div>

                                        <div className="pt-4 px-3">
                                            <h5 className="mb-1 font-semibold text-lg">Catering</h5>
                                            <span className="text-slate-400 text-justify">Handle complex catering orders, simplifies invoicing and provides detailed reports.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tiny-slide" >
                                <div className="picture-item p-4 rounded-md" >
                                    <div className="">
                                        <div className="relative">
                                            <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                                                <img src='images/index/bakery.avif' className="rounded-t-md shadow" alt="" />
                                            </div>
                                        </div>

                                        <div className="pt-4 px-3">
                                            <h5 className="mb-1 font-semibold text-lg">Bakery</h5>
                                            <span className="text-slate-400 text-justify">Managing inventory, tracking orders for custom cakes, and integrating with loyalty.   </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tiny-slide" >
                                <div className="picture-item p-4 rounded-md" >
                                    <div className="">
                                        <div className="relative">
                                            <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                                                <img src='images/index/truck.jpg' className="rounded-t-md shadow" alt="" />
                                            </div>
                                        </div>

                                        <div className="pt-4 px-3">
                                            <h5 className="mb-1 font-semibold text-lg">Food Carts</h5>
                                            <span className="text-slate-400 text-justify">Simplifies order processing and payment handling on the go wit quick transactions.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tiny-slide" >
                                <div className="picture-item p-4 rounded-md" >
                                    <div className="">
                                        <div className="relative">
                                            <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                                                <img src='images/index/club.jpeg' className="rounded-t-md shadow" alt="" />
                                            </div>
                                        </div>

                                        <div className="pt-4 px-3">
                                            <h5 className="mb-1 font-semibold text-lg">Clubs</h5>
                                            <span className="text-slate-400 text-justify">   Elevates the guest experience with its sleek interface designed for fast-paced environments. </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tiny-slide" >
                                <div className="picture-item p-4 rounded-md" >
                                    <div className="">
                                        <div className="relative">
                                            <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                                                <img src='images/index/franchise2.png' className="rounded-t-md shadow" alt="" />
                                            </div>
                                        </div>

                                        <div className="pt-4 px-3">
                                            <h5 className="mb-1 font-semibold text-lg">Franchise Network</h5>
                                            <span className="text-slate-400 text-justify">Unified solution that enhances consistency and efficiency across all locations.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tiny-slide" >
                                <div className="picture-item p-4 rounded-md" >
                                    <div className="">
                                        <div className="relative">
                                            <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                                                <img src='images/index/dhaba.jpg' className="rounded-t-md shadow" alt="" />
                                            </div>
                                        </div>

                                        <div className="pt-4 px-3">
                                            <h5 className="mb-1 font-semibold text-lg">Dhabas</h5>
                                            <span className="text-slate-400 text-justify">Modernizes operations with a user-friendly interface tailored for traditional settings.  </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tiny-slide" >
                                <div className="picture-item p-4 rounded-md" >
                                    <div className="">
                                        <div className="relative">
                                            <div className="shadow dark:shadow-gray-800 p-5 pb-0 rounded-md bg-indigo-600/5 dark:bg-indigo-600/30">
                                                <img src='images/index/multiplex.jpg' className="rounded-t-md shadow" alt="" />
                                            </div>
                                        </div>

                                        <div className="pt-4 px-3">
                                            <h5 className="mb-1 font-semibold text-lg">Multiplex/ Cinema</h5>
                                            <span className="text-slate-400 text-justify">Revolutionizes the movie-going experience with its fast, intuitive system.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TinySlider>
                    </div>
                </div>
            </section>









            <div className="container relative mt-16">
                {/* <div className="grid md:grid-cols-12 grid-cols-1 pb-8 items-end">
                        <div className="lg:col-span-12 md:col-span-12 md:text-start text-center">
                            <h2 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-center">The Future of Foodservice: Discover Top Restaurant Software. </h2>
                            <p className="text-slate-400  text-center">Discover the best restaurant POS software in India, offering comprehensive POS billing software <br/>and seamless restaurant POS point of sale solutions.</p>
                        </div>
                    </div> */}

                {/* <div className="grid grid-cols-1 mt-2 md:mt-6 relative">
                        <div className="tiny-five-item">
                            <TinySlider settings={settings}>
                                    <div className="tiny-slide" >
                                        <div className="group relative overflow-hidden rounded-md shadow dark:shadow-gray-800 hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out m-2 mb-5">
                                        <img src='images/barcode/handy.jpg' className="" alt="" />
                                            
                                            <div className="p-6 pt-0 mt-4">

                                                <div className="mt-4">
                                                    <Link  className="text-lg font-semibold hover:text-indigo-600 duration-500 ease-in-out block">Handy POS</Link>
                                                    <span className="text-slate-400 text-justify">Table management for assigning orders to specific tables.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tiny-slide" >
                                        <div className="group relative overflow-hidden rounded-md shadow dark:shadow-gray-800 hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out m-2 mb-5">
                                        <img src='images/printers/printer8.jpg' className="" alt="" />
                                            
                                            <div className="p-6 pt-0 mt-4">

                                                <div className="mt-4">
                                                    <Link  className="text-lg font-semibold hover:text-indigo-600 duration-500 ease-in-out block">Printers</Link>
                                                    <span className="text-slate-400 text-justify">Photo and description integration for menu items.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tiny-slide" >
                                        <div className="group relative overflow-hidden rounded-md shadow dark:shadow-gray-800 hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out m-2 mb-5">
                                        <img src='images/windows/android11.jpg' className="" alt="" />
                                            
                                            <div className="p-6 pt-0 mt-4">

                                                <div className="mt-4">
                                                    <Link  className="text-lg font-semibold hover:text-indigo-600 duration-500 ease-in-out block">Android POS</Link>
                                                    <span className="text-slate-400 text-justify">Real-time inventory updates with each sale.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tiny-slide" >
                                        <div className="group relative overflow-hidden rounded-md shadow dark:shadow-gray-800 hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out m-2 mb-5">
                                        <img src='images/android/android3.jpg' className="" alt="" />
                                            
                                            <div className="p-6 pt-0 mt-4">

                                                <div className="mt-4">
                                                    <Link  className="text-lg font-semibold hover:text-indigo-600 duration-500 ease-in-out block">Windows POS</Link>
                                                    <span className="text-slate-400 text-justify">Support for various payment methods (credit/debit cards, mobile payments, cash).</span><br></br>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tiny-slide" >
                                        <div className="group relative overflow-hidden rounded-md shadow dark:shadow-gray-800 hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out m-2 mb-5">
                                        <img src='images/barcode/barcode1.jpg' className="" alt="" />
                                            
                                            <div className="p-6 pt-0 mt-4">

                                                <div className="mt-4">
                                                    <Link  className="text-lg font-semibold hover:text-indigo-600 duration-500 ease-in-out block">Barcode Scanner </Link>
                                                    <span className="text-slate-400">Store customer profiles and preferences.</span><br></br>
                                                    <span className="text-slate-400">Loyalty programs and reward tracking.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tiny-slide" >
                                        <div className="group relative overflow-hidden rounded-md shadow dark:shadow-gray-800 hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out m-2 mb-5">
                                        <img src='images/barcode/other.jpg' className="" alt="" />
                                            
                                            <div className="p-6 pt-0 mt-4">

                                                <div className="mt-4">
                                                    <Link  className="text-lg font-semibold hover:text-indigo-600 duration-500 ease-in-out block">Other Accessories</Link>
                                                    <span className="text-slate-400">Sales reports and performance metrics.</span><br></br>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </TinySlider>
                        </div>
                    </div> */}


            </div>


            {/* faqs Start here */}
            {/* <div className="container relative pt-5 mt-12 mb-16 md-mb-2">
        <div className="grid grid-cols-1 pb-3 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Frequently Asked Questions</h3>

            <p className="text-slate-400 max-w-xl mx-auto">Find quick answers to common questions about our POS software.</p>
        </div>

        <div className="relative grid md:grid-cols-12 grid-cols-1 items-center  gap-[30px]">
            <div className="md:col-span-6">
                <div className="relative">
                    <div className="relative rounded-xl overflow-hidden ">
                          <img src='images/faq.png'alt="" className=''/>
                    </div>
                </div>
            </div>

            <div className="md:col-span-6">
                <div id="accordion-collapse">
                    {accordionData.slice(0,4).map((item, index) => (
                        <div key={index} className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                            <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                <button type="button" onClick={() => toggleAccordion(index)} className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === index ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`} data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                    <span>{item.title}</span>
                                    <svg data-accordion-icon className={`${activeIndex === index ? "rotate-180" : "rotate-270" } size-4 shrink-01`}  fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                    </svg>
                                </button>
                            </h2>
                            {activeIndex === index && (
                                <div>
                                    <div className="p-5">
                                        <p className="text-slate-400 dark:text-gray-400">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p>
                                    </div>
                                </div>
                            )}
                        </div>

                    ))}
                </div>
            </div>
        </div>
      </div> */}



            <div className="container relative py-16">
                <div className="grid grid-cols-1 pb-8 ">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Oussr key Feature</h3>
                    <p className="text-slate-400 width70 ">Revolutionize your business operations and enhance efficiency with our advanced <br/>restaurant POS software.</p>
                </div>

                <div className="grid lg:grid-cols-12 md:grid-cols-12 grid-cols-1 mt-8 gap-[30px] items-center">
                    <div className="lg:col-span-4 md:col-span-6 lg:order-1 order-2">
                        <div className="grid grid-cols-1 gap-[30px]">
                           

                            <div className="group flex transition-all duration-500 ease-in-out xl:p-3">
                                <div className="flex md:order-2 order-1 align-middle justify-center items-center size-14 mt-1 bg-indigo-600/5  group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                    <img src="images/index/order-now.png" alt="" class="appIcon icon-blue" />
                                </div>
                                <div className="flex-1 md:order-1 order-2 md:text-end md:me-4 md:ms-0 ms-4">
                                    <h4 className="mb-0 text-lg font-medium">Direct Ordering</h4>
                                    <p className="text-slate-400 mt-2">Streamline the process of placing and processing orders. </p>
                                </div>
                            </div>

                            <div className="group flex transition-all duration-500 ease-in-out xl:p-3">
                                <div className="flex md:order-2 order-1 align-middle justify-center items-center size-14 mt-1 bg-indigo-600/5  group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                    <img src="images/index/payment.png" alt="" class="appIcon icon-blue" />
                                </div>
                                <div className="flex-1 md:order-1 order-2 md:text-end md:me-4 md:ms-0 ms-4">
                                    <h4 className="mb-0 text-lg font-medium">Payments</h4>
                                    <p className="text-slate-400 mt-2">Process transactions and manage payments efficiently.</p>
                                </div>
                            </div>

                            <div className="group flex transition-all duration-500 ease-in-out xl:p-3">
                                <div className="flex md:order-2 order-1 align-middle justify-center items-center size-14 mt-1 bg-indigo-600/5  group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                    <img src="images/index/inventory-management.png" alt="" class="appIcon icon-blue" />
                                </div>
                                <div className="flex-1 md:order-1 order-2 md:text-end md:me-4 md:ms-0 ms-4">
                                    <h4 className="mb-0 text-lg font-medium">Inventory management</h4>
                                    <p className="text-slate-400 mt-2">Efficiently track and manage your outlet stock levels.</p>
                                </div>
                            </div>

                            <div className="group flex transition-all duration-500 ease-in-out xl:p-3">
                                <div className="flex md:order-2 order-1 align-middle justify-center items-center size-14 mt-1 bg-indigo-600/5  group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                    <img src="images/index/table_management.png" alt="" class="appIcon icon-blue" />
                                </div>
                                <div className="flex-1 md:order-1 order-2 md:text-end md:me-4 md:ms-0 ms-4">
                                    <h4 className="mb-0 text-lg font-medium">Table Management</h4>
                                    <p className="text-slate-400 mt-2">Assign tables to customers and track table status.</p>
                                </div>
                            </div>
                        </div>
                    </div>


                  <div class="lg:col-span-4 md:col-span-12 lg:mx-8 lg:order-2 order-1">
                    <img src='images/index/app.png' className="mx-auto mobile_image" alt="" />
                        <div className="tiny-five-item">
                            <img src='images/index/app.png' className="mx-auto mobile_image" alt="" />
                            <TinySlider settings={settings4}>
                                <div className="tiny-slide pt-16 pb-24" >
                                    <img src='images/index/mobile_slider1.jpg' className="mx-auto mobile_slider_images" alt="" />
                                </div>

                                <div className="tiny-slide pt-16 pb-24" >
                                    <img src='images/index/mobile_slider4.png' className="mx-auto mobile_slider_images" alt="" />
                                </div>

                                <div className="tiny-slide pt-16 pb-24" >
                                    <img src='images/index/mobile_slider2.jpg' className="mx-auto mobile_slider_images" alt="" />
                                </div>

                                <div className="tiny-slide pt-16 pb-24" >
                                    <img src='images/index/mobile_slider3.jpg' className="mx-auto mobile_slider_images" alt="" />
                                </div>
                            </TinySlider>
                        </div>
                  </div>

                    <div className="lg:col-span-4 md:col-span-6 order-3">
                        <div className="grid grid-cols-1 gap-[30px]">
                            <div className="group flex transition-all duration-500 ease-in-out xl:p-3">
                                <div className="flex align-middle justify-center items-center size-14 mt-1 bg-indigo-600/5  group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                    <img src="images/index/integration.png" alt="" class="appIcon icon-blue" />
                                </div>
                                <div className="flex-1 ms-4">
                                    <h4 className="mb-0 text-lg font-medium">Integrations</h4>
                                    <p className="text-slate-400 mt-2">Connects with external systems and enhance functionality.</p>
                                </div>
                            </div>

                            <div className="group flex transition-all duration-500 ease-in-out xl:p-3">
                                <div className="flex align-middle justify-center items-center size-14 mt-1 bg-indigo-600/5  group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                    <img src="images/index/loyal-customer.png" alt="" class="appIcon icon-blue" />
                                </div>
                                <div className="flex-1 ms-4">
                                    <h4 className="mb-0 text-lg font-medium">Loyalty</h4>
                                    <p className="text-slate-400 mt-2">Build and maintain customer relationships by rewarding points.</p>
                                </div>
                            </div>

                            <div className="group flex transition-all duration-500 ease-in-out xl:p-3">
                                <div className="flex align-middle justify-center items-center size-14 mt-1 bg-indigo-600/5  group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                    <img src="images/index/cooking.png" alt="" class="appIcon icon-blue" />
                                </div>
                                <div className="flex-1 ms-4">
                                    <h4 className="mb-0 text-lg font-medium">Recipe Management</h4>
                                    <p className="text-slate-400 mt-2">Streamline kitchen operations and ensure consistent quality.</p>
                                </div>
                            </div>

                            <div className="group flex transition-all duration-500 ease-in-out xl:p-3">
                                <div className="flex align-middle justify-center items-center size-14 mt-1 bg-indigo-600/5  group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                    <img src="images/index/report2.png" alt="" class="appIcon icon-blue" />
                                </div>
                                <div className="flex-1 ms-4">
                                    <h4 className="mb-0 text-lg font-medium">Reporting </h4>
                                    <p className="text-slate-400 mt-2">Detailed reports to gain insights into your business.</p>
                                </div>
                            </div>

                            {/* <div className="group flex transition-all duration-500 ease-in-out xl:p-3">
                                <div className="flex align-middle justify-center items-center size-14 mt-1 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl shadow-sm dark:shadow-gray-800 transition-all duration-500 ease-in-out">
                                <img src="images/icons/languages.png" alt="" class="appIcon"/>
                                </div>
                                <div className="flex-1 ms-4">
                                    <h4 className="mb-0 text-lg font-medium">Language</h4>
                                    <p className="text-slate-400 mt-3">One disadvantage of Lorum Ipsum is that in Latin frequently than others</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='py-16'>
                <UserFeedBack />
            </div> */}



            {/* <section className='bg-white p-4 relative py-16'>
                <div className="container relative py-16 ">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[10px]">
                        <div className="lg:col-span-5 md:col-span-6">
                            <img src='images/index/form.avif' alt=""  className='p-4 border bg-white shadow radius-12'/>
                        </div>

                        <div className="lg:col-span-7 md:col-span-6">
                            <div className="lg:ms-5">
                                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                                    <h3 className="mb-6 text-2xl leading-normal font-medium">Get in touch !</h3>

                                    <form id="create-course-form" method='post' onSubmit={handleSubmit}>
                                    <div className="grid lg:grid-cols-12 lg:gap-6">
                                        <div className="lg:col-span-4 mb-5">
                                            <div className="text-start">
                                                <label htmlFor="name" className="font-semibold">Your Name:</label>
                                                <div className="form-icon relative mt-2">
                                                    <Icon.User className="size-4 absolute top-3 start-4"></Icon.User>
                                                    <input  name="name" id="name" type="text" className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Name :" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="lg:col-span-8 mb-5">
                                            <div className="text-start">
                                                <label htmlFor="email" className="font-semibold">Your Email:</label>
                                                <div className="form-icon relative mt-2">
                                                    <Icon.Mail className="size-4 absolute top-3 start-4"></Icon.Mail>
                                                    <input  name="email" id="email" type="email" className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Email :" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <div className="text-start">
                                                <label htmlFor="subject" className="font-semibold">Your Question:</label>
                                                <div className="form-icon relative mt-2">
                                                    <Icon.Book className="size-4 absolute top-3 start-4"></Icon.Book>
                                                    <input name="subject" id="subject" className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Subject :" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-5">
                                            <div className="text-start">
                                                <label htmlFor="comments" className="font-semibold">Your Comment:</label>
                                                <div className="form-icon relative mt-2">
                                                    <Icon.MessageCircle className="size-4 absolute top-3 start-4"></Icon.MessageCircle>
                                                    <textarea  name="comments" id="comments" className="form-input ps-11 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Message :"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type='submit' className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full">Submit</button>
                                    
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <Footer />
            <CookieModal />

            <section className='popup'>
                <div className='container'>
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px] mx-auto text-center">
                        <div className="lg:col-start-3 lg:col-span-8 md:col-start-2 md:col-span-10">
                            <div className="bg-white book_demo_form relative dark:bg-slate-900 overflow-auto rounded-md shadow dark:shadow-gray-800 p-8 pb-16 mt-5 overflow-auto ">
                                <h3 class="mb-8 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold"> Flavour POS Demo</h3>
                                <img src='images/index/close2.png' className='crose' onClick={Close}></img>
                                <form id="create-course-form" method='post' onSubmit={handleSubmit}>
                                    <div className="grid md:grid-cols-2 grid-cols-1 mt-2 gap-[35px]">
                                        <div className="form-group text_box text-left">
                                            <label className="f_p text_c f_400 ">Name *</label><br />
                                            <input className="form-control" type="text" name="name" placeholder="Your Name" required="" />
                                        </div>

                                        <div className="form-group text_box text-left">
                                            <label className="f_p text_c f_400 ">Mobile No *</label><br />

                                            <span className='d-flex'>
                                                <select>
                                                    <option>+91</option>
                                                    <option>+91</option>
                                                    <option>+91</option>
                                                    <option>+91</option>
                                                </select>
                                                <input className="form-control" type="number" name="phone" placeholder="Your Mobile No " required="" />
                                            </span>

                                        </div>


                                        <div className="form-group text_box text-left mt-3">
                                            <label className="f_p text_c f_400 ">Email *</label><br />
                                            <input className="form-control" type="email" name="email" placeholder="Your Email" required="" />
                                        </div>


                                        <div className="form-group text_box text-left mt-3">
                                            <label className="f_p text_c f_400 ">Restaurant Brand *</label><br />
                                            <input className="form-control" type="text" name="institute" placeholder="Your Restaurant Brand *" required="" />
                                        </div>




                                    </div>

                                    <div className="grid md:grid-cols-2 grid-cols-1 mt-6 gap-[15px]">
                                        <div className="form-group text_box text-left">
                                            <label className="f_p text_c f_400 ">Designation  *</label><br />
                                            <input className="form-control" type="text" name="subject" placeholder="Designation " required="" />
                                        </div>

                                        <div className="form-group text_box text-left">
                                            <label className="f_p text_c f_400 ">No. of Restaurants *</label><br />
                                            <select className="form-control">
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                            </select>
                                        </div>

                                    </div>


                                    <div className="grid md:grid-cols-2 grid-cols-1 mt-6 gap-[15px]">
                                        <div className="form-group text_box text-left">
                                            <label className="f_p text_c f_400 ">Restaurant Format  *</label><br />
                                            <input className="form-control" type="text" name="subject" placeholder="Format " required="" />
                                        </div>

                                        <div className="form-group text_box text-left">
                                            <label className="f_p text_c f_400 ">Country *</label><br />
                                            <select className="form-control">
                                                <option>India</option>
                                                <option>Pakistan</option>
                                                <option>Bangladesh</option>
                                                <option>England</option>
                                                <option>China</option>
                                            </select>
                                        </div>

                                    </div>

                                    <div className="grid md:grid-cols-2 grid-cols-1 mt-6 gap-[15px]">
                                        <div className="form-group text_box text-left">
                                            <label className="f_p text_c f_400 ">Date  *</label><br />
                                            <input className="form-control" type="date" name="subject" placeholder="Format " required="" />
                                        </div>

                                        <div className="form-group text_box text-left">
                                            <label className="f_p text_c f_400 ">Time *</label><br />
                                            <input className="form-control" type="time" name="subject" placeholder="Format " required="" />

                                        </div>


                                    </div>


                                    <div className="grid md:grid-cols-1 grid-cols-1 mt-6 gap-[30px] d-flex justify-content-center">
                                        {/* <div className="form-group text_box text-left">

                                        </div> */}

                                        <button type='submit' className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full">Schedule Demo</button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
