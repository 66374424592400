import React, { useState } from 'react'

import { Link } from 'react-router-dom';
import contact from '../../assets/images/contact.svg';
import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';
import Topbar from '../../component/Topbar'


// import {MdKeyboardArrowRight} from "../../assets/icons/icons"
import * as Icon from 'react-feather';
// import { contactData } from '../../data/data';

import axios from "axios";
// import SweetAlert2 from 'react-sweetalert2';
import Swal from 'sweetalert2'
export default function ContactOne() {
    const [name,setName] = useState();
    const [email,setEmail] = useState();
    const [question,setQuestion] = useState();
    const [comment,setComment] = useState();

    const handleSubmit = (event) => {
        event.preventDefault();
    
    
            let data = JSON.stringify({
            "name": name,
            "email": email,
            "question": question,
            "comment": comment,
    
            });
    
            let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://edukrypt.com/api/sendEmailContactUs',
            headers: {
                'Content-Type': 'application/json'
            },
            data : data
            };
    
            axios.request(config)
            .then((response) => {
    
                document.getElementById("create-course-form").reset();
                Swal.fire(response.data.message);
    
    
    
            })
            .catch((error) => {
            console.log(error);
            });
    
    
      };
    
    return (
        <>
        <Topbar/>
                        <Navbar navClass="nav-sticky navbar-color-orange" />

            <div className="relative mt-9">
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
                        <div  className="text-center px-6 mt-6">
                            <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                <img src="images/icons/email.png" alt="" class="appIcon icon-orange"/>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-xl font-medium">Email</h5>

                                <div className="mt-5">
                                    <Link to="mailto:connect@edukrypt.com" className="text-slate-400 mt-3">connect@edukrypt.com</Link>
                                </div>
                            </div>
                        </div>

                        <div  className="text-center px-6 mt-6">
                            <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                <img src="images/icons/telephone.png" alt="" class="appIcon icon-orange"/>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-xl font-medium">Contact</h5>

                                <div className="mt-5 ms-2">
                                    <Link to="tel:+152534-468-854" className="text-slate-400 mt-3">8851286001</Link>
                                </div>
                            </div>
                        </div>

                        <div  className="text-center px-6 mt-6">
                            <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                <img src="images/icons/location.png" alt="" class="appIcon icon-orange"/>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-xl font-medium">Address</h5>
                                <p class="text-slate-400 mt-3">7th Floor, TS-01, Galaxy Blue Sapphire Plaza, Plot No. C-03, Sector-4, Greater Noida, Uttar Pradesh-201306</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container relative md:mt-24 mt-16">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-7 md:col-span-6">
                            <img src={contact} alt="" />
                        </div>

                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="lg:ms-5">
                                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                                    <h3 className="mb-6 text-2xl leading-normal font-medium">Get in touch !</h3>

                                    <form id="create-course-form" method='post' onSubmit={handleSubmit}>
                                    <div className="grid lg:grid-cols-12 lg:gap-6">
                                        <div className="lg:col-span-6 mb-5">
                                            <div className="text-start">
                                                <label htmlFor="name" className="font-semibold">Your Name:</label>
                                                <div className="form-icon relative mt-2">
                                                    <Icon.User className="size-4 absolute top-3 start-4"></Icon.User>
                                                    <input onChange={(e) => {setName(e.target.value)}} name="name" id="name" type="text" className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Name :" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="lg:col-span-6 mb-5">
                                            <div className="text-start">
                                                <label htmlFor="email" className="font-semibold">Your Email:</label>
                                                <div className="form-icon relative mt-2">
                                                    <Icon.Mail className="size-4 absolute top-3 start-4"></Icon.Mail>
                                                    <input onChange={(e) => {setEmail(e.target.value)}} name="email" id="email" type="email" className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Email :" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <div className="text-start">
                                                <label htmlFor="subject" className="font-semibold">Your Question:</label>
                                                <div className="form-icon relative mt-2">
                                                    <Icon.Book className="size-4 absolute top-3 start-4"></Icon.Book>
                                                    <input onChange={(e) => {setQuestion(e.target.value)}} name="subject" id="subject" className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Subject :" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-5">
                                            <div className="text-start">
                                                <label htmlFor="comments" className="font-semibold">Your Comment:</label>
                                                <div className="form-icon relative mt-2">
                                                    <Icon.MessageCircle className="size-4 absolute top-3 start-4"></Icon.MessageCircle>
                                                    <textarea onChange={(e) => {setComment(e.target.value)}} name="comments" id="comments" className="form-input ps-11 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Message :"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type='submit' className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full">Submit</button>
                                    
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container-fluid relative">
                <div className="grid grid-cols-1">
                    <div className="w-full leading-[0] border-0">
                        <iframe  title='google' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.751948701075!2d77.43240407457182!3d28.607217385231436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cef9ae97c0c11%3A0xd27e41efecfd60d8!2sGalaxy%20Blue%20Sapphire!5e0!3m2!1sen!2sin!4v1719480674352!5m2!1sen!2sin" style={{border:0}} className="w-full h-[500px]" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
