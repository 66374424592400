import React from 'react'
// import { Link } from 'react-router-dom';
import Topbar from '../../component/Topbar'
import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';
// import { blogData } from '../../data/data';

// import {MdKeyboardArrowRight,FaArrowRight, MdOutlineKeyboardArrowLeft} from "../../assets/icons/icons"

export default function Blog() {
  
    return (
        <>
            {/* <Navbar navClass="nav-light"/> */}
      <Topbar />
      <Navbar />

           
            {/* <div className="relative pt-36">
                <div className="grid grid-cols-1 pb-2 text-center">
                    <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Our Products </h3>

                    <p className="text-slate-400 max-w-xl mx-auto">Explore our wide range of restaurant POS software</p>
                </div>
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div> */}

            <section className="relative pt-36 mt-8 pb-16">
                {/* BLog Section start here */}
        
                    <div className="container relative ">
                            <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                                <div className="lg:col-span-12 md:col-span-6">
                                <div>
                                    {/* <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">Products</h6> */}
                                    <h3 className="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0 text-center">Our Products  </h3>
                                    <p className="text-slate-400  mt-2 text-center">Explore our wide range of restaurant POS software</p>
                                </div>
                                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-[30px] py-16">
                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            {/* <img src={Blog}/> */}
                                            <img src='images/index/pos4.png' alt="" className=''/>
                    
                                            <div className="content p-6">
                                                <h5 className="md:text-2xl md:leading-normal text-2xl leading-normal font-semibold">POS</h5>
                                                <p className="text-slate-400 mt-3 text-justify">Our restaurant POS software streamlines operations with intuitive features designed for efficiency. From order management and inventory tracking to real-time reporting, it enhances both front-of-house and back-of-house performance, Whether for fine dining or quick service, Flavour POS is essential for improving customer experiences.</p>
                                                
                                            </div>
                                        </div>
                    
                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/kds5.png' alt="" className=''/>
                    
                                            <div className="content p-6">
                                                <h5 className="md:text-2xl md:leading-normal text-2xl leading-normal font-semibold">KDS</h5>

                                                <p className="text-slate-400 mt-3 text-justify">Our KDS (Kitchen Display System) software revolutionizes kitchen operations by providing real-time order tracking and streamlined communication between front and back of house. Designed to integrate seamlessly with POS system, our KDS software helps your kitchen staff stay organized and efficient, ultimately improving overall service quality.</p>
                                                
                                            </div>
                                        </div>

                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/token3.png' alt="" className=''/>
                    
                                            <div className="content p-6">
                                                <h5 className="md:text-2xl md:leading-normal text-2xl leading-normal font-semibold">Token Management</h5>

                                                <p className="text-slate-400 mt-3 text-justify">Our token display software enhances customer experience by providing real-time updates on order status through digital displays. It helps streamline operations by clearly communicating wait times and queue numbers, reducing customer anxiety and improving service efficiency.</p>
                                                
                                            </div>
                                        </div>

                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/menu2.jpg' alt="" className=''/>
                    
                                            <div className="content p-6">
                                                {/* <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">QR Menu</a> */}
                                                <h5 className="md:text-2xl md:leading-normal text-2xl leading-normal font-semibold">QR Menu</h5>

                                                <p className="text-slate-400 mt-3 text-justify">Our restaurant QR menu feature offers a modern, contactless dining experience by allowing customers to view and order from your menu using their smartphones. With easy-to-update digital menus and real-time availability updates, it simplifies the ordering process and enhances customer convenience.</p>
                                                
                                            </div>
                                        </div>


                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/calling3.png' alt="" className=''/>
                    
                                            <div className="content p-6">
                                                {/* <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">QR Calling </a> */}
                                                <h5 className="md:text-2xl md:leading-normal text-2xl leading-normal font-semibold">QR Calling</h5>
                                                
                                                <p className="text-slate-400 mt-3 text-justify">Our restaurant QR calling software transforms customer service by enabling diners to request assistance or place special requests directly from their smartphones using a QR code. This contactless solution improves service efficiency and enhances the dining experience.</p>
                                                
                                            </div>
                                        </div>
                    
                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/service4.png' alt="" className=''/>
                                            <div className="content p-6">
                                                {/* <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Server App</a> */}
                                                <h5 className="md:text-2xl md:leading-normal text-2xl leading-normal font-semibold">Server App</h5>

                                                <p className="text-slate-400 mt-3 text-justify">Our restaurant captain app enhances service efficiency by equipping front-of-house staff with a powerful tool to manage orders, track table status, and communicate seamlessly with the kitchen which delivers a superior dining experience.</p>
                                                
                                            </div>
                                        </div>

                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src='images/index/kiosk2.png' alt="" className=''/>
                                            <div className="content p-6">
                                                {/* <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Kiosk</a> */}
                                                <h5 className="md:text-2xl md:leading-normal text-2xl leading-normal font-semibold">Kiosk</h5>

                                                <p className="text-slate-400 mt-3 text-justify">Our restaurant kiosk app modernizes the ordering process by allowing customers to place their own orders directly from a self-service kiosk. With an intuitive interface and customizable menu options, it speeds up order processing and reduces wait times.</p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>

            </section>
            <Footer />

        </>
    )
}
