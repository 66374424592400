import React from 'react'
// import logo_dark from '../../assets/images/logo-dark.png';
import logo_light from '../../assets/images/flavour.png';
import '../../assets/libs/@mdi/font/css/materialdesignicons.min.css';
import '../../assets/css/tailwind.css';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
// import * as Icon from 'react-feather';

export default function Navbar(props) {
    let { navClass, navJustify } = props;
    let [isMenu, setisMenu] = useState(false);
    let [manu , setManu] = useState('');
    let location = useLocation();
    
    useEffect(()=>{
        let current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        setManu(current)

        function windowScroll() {
            const navbar = document.getElementById("topnav");
            if (
                document.body.scrollTop >= 50 ||
                document.documentElement.scrollTop >= 50
            ) {
                if (navbar !== null) {
                    navbar?.classList.add("nav-sticky");
                }
            } else {
                if (navbar !== null) {
                    navbar?.classList.remove("nav-sticky");
                }
            }
        }
        window.addEventListener("scroll", windowScroll);
        window.scrollTo(0, 0)
        return () => {
            window.removeEventListener('scroll', windowScroll);
        };

    },[location.pathname.substring(location.pathname.lastIndexOf('/') + 1)])
    

    const toggleMenu = () => {
        setisMenu(!isMenu);
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    };



    const Topbar = ()=>{
        return(<>
                    <div className='topbar '>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-[30px]'>
                <div className='col-md-6'>
                    {/* <div>kaif</div> */}
                </div>

                <div className='col-md-6'>
                    <div>
                        <ul className="list-none footer-list d-flex justify-content-end">
                                <li><Link to='tel:08069640643' className=" hover:text-gray-400 duration-500 ease-in-out inline-flex items-center mr-4"><i class="fa fa-phone mr-2 text-orange" aria-hidden="true"></i>08069640643</Link></li>
                                <li><Link to='mailto:connect@edukrypt.com' className=" hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><i class="fa fa-envelope-o mr-2 text-orange" aria-hidden="true"></i>                                connect@edukrypt.com</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
            </>)
    }


    return (
        
       
       
        <nav id="topnav" className={`defaultscroll ${navClass === "nav-light" ? '' : navClass === "nav-sticky" ?
        'bg-white dark:bg-slate-900' : ''}`}>

    
        <div className="container relative">
            {
                navClass === "nav-light" ?
                    <Link className="logo" to="/index">
                        <span className="inline-block dark:hidden">
                            <img src={logo_light} className="l-dark" height="24" alt="" />
                            <img src={logo_light} className="l-light" height="24" alt="" />
                        </span>
                        <img src={logo_light} height="24" className="hidden dark:inline-block" alt="" />
                    </Link>
                    :
                    <Link className="logo" to="/index">
                        <img src={logo_light} className="inline-block dark:hidden" alt="" />
                        <img src={logo_light} className="hidden dark:inline-block" alt="" />
                    </Link>
            }

            <div className="menu-extras">
                <div className="menu-item">
                    <Link to="#" className={`navbar-toggle ${isMenu ? 'open' : ''}`} id="isToggle" onClick={() => toggleMenu()}>
                        <div className="lines">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </Link>
                </div>
            </div>

           

            {
                // navClass !== 'nav-light' ? <ul className={`buy-button list-none space-x-1 mb-0`} >
                //         <li className="inline mb-0">
                //             <Link to="#" className="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-indigo-600/5 hover:bg-indigo-600 border border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white"><Icon.Settings className="size-4" /></Link>
                //         </li>

                //         <li className="inline ps-1 mb-0">
                //             <Link to="https://1.envato.market/techwind-react" target="_blank" className="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white"><Icon.ShoppingCart className="size-4" /></Link>
                //         </li>
                        
                //     </ul>
                //     :
                //     <ul className="buy-button list-none space-x-1 mb-0">
                //     <li className="inline mb-0">
                //         <Link to="#">
                //             <span className="login-btn-primary"><span className="size-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600/5 hover:bg-indigo-600 border border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white"><Icon.Settings className="size-4" /></span></span>
                //             <span className="login-btn-light"><span className="size-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 border hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"><Icon.Settings className="size-4" /></span></span>
                //         </Link>
                //     </li>
            
                //     <li className="inline ps-1 mb-0">
                //         <Link to="https://1.envato.market/techwind-react" target="_blank">
                //             <div className="login-btn-primary"><span className="size-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white"><Icon.ShoppingCart className="size-4" /></span></div>
                //             <div className="login-btn-light"><span className="size-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 border hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"><Icon.ShoppingCart className="size-4" /></span></div>
                //         </Link>
                //     </li>
                // </ul>
            }
           
            <div id="navigation" style={{ display: isMenu ? 'block' : 'none' }}>
                <ul className={`navigation-menu ${navClass} ${navJustify}`}>
                    {/* <li className={manu === "index" || "" ? "active" : ""}><Link to="/product" className="sub-menu-item">Product</Link></li> */}

                    <li className={`${["page-aboutus", "page-services","page-team", "page-pricing","page-testimonial","user-profile","user-billing","user-payment","user-invoice","user-notification","user-setting","property-listing","property-detail","course-listing","course-detail","nft-explore","nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail","food-recipe","shop-grid","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","photography-about","photography-portfolio","page-job-grid","page-job-detail","page-job-apply","page-job-post","page-job-career","page-job-candidates","page-job-candidate-detail","page-job-companies","page-Job-company-detail","forums-topic","forums-comments","helpcenter-overview","helpcenter-faqs","helpcenter-guides","helpcenter-support","blog","blog-sidebar","blog-detail","blog-standard-post","blog-slider-post","blog-gallery-post","blog-youtube-post","blog-vimeo-post","blog-audio-post","blog-blockquote-post","blog-left-sidebar-post","email-confirmation","email-password-reset","email-alert","email-invoice","auth-login","auth-signup","auth-re-password","auth-lock-screen","page-terms","page-privacy","page-comingsoon","page-maintenance","page-error","page-thankyou","contact-detail","contact-one","contact-two"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`}>
                        <Link to="/restaurant">Restaurants </Link>
                        {/* <span className="menu-arrow"></span> */}
                        {/* <ul className="submenu">
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/multi_business"> Multiple business/shops </Link>
                              
                            </li>
                            <li className={`${["user-profile", "user-billing","user-payment", "user-invoice", "user-social","user-notification","user-setting"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/multi_location"> Multiple Locations</Link>
                              
                            </li>
                            <li className={`${["property-listing", "property-detail"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/multi_language"> Multi Language</Link>
                               
                            </li>
                            <li className={`${["course-listing", "course-detail"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/work_offline"> Works Offline</Link>
                               
                            </li>

                            <li className={`${["nft-explore", "nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/express_checkout">  Express Checkout </Link>
                         
                            </li>

                            <li className={`${["shop-grid", "shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/barcode_sticker"> Barcode Stickers </Link>
                             
                            </li>
                          
                           
                        </ul> */}
                    </li>

                    <li className={`${["page-aboutus", "page-services","page-team", "page-pricing","page-testimonial","user-profile","user-billing","user-payment","user-invoice","user-notification","user-setting","property-listing","property-detail","course-listing","course-detail","nft-explore","nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail","food-recipe","shop-grid","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","photography-about","photography-portfolio","page-job-grid","page-job-detail","page-job-apply","page-job-post","page-job-career","page-job-candidates","page-job-candidate-detail","page-job-companies","page-Job-company-detail","forums-topic","forums-comments","helpcenter-overview","helpcenter-faqs","helpcenter-guides","helpcenter-support","blog","blog-sidebar","blog-detail","blog-standard-post","blog-slider-post","blog-gallery-post","blog-youtube-post","blog-vimeo-post","blog-audio-post","blog-blockquote-post","blog-left-sidebar-post","email-confirmation","email-password-reset","email-alert","email-invoice","auth-login","auth-signup","auth-re-password","auth-lock-screen","page-terms","page-privacy","page-comingsoon","page-maintenance","page-error","page-thankyou","contact-detail","contact-one","contact-two"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`}>
                        <Link to="/product">Products</Link>
                        {/* <span className="menu-arrow"></span> */}
                        {/* <ul className="submenu">
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/pos"> POS </Link>
                              
                            </li>
                            <li className={`${["user-profile", "user-billing","user-payment", "user-invoice", "user-social","user-notification","user-setting"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/accounting"> Accounting & Bookkeeping</Link>
                              
                            </li>
                            <li className={`${["property-listing", "property-detail"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/inventory"> Inventory Management </Link>
                               
                            </li>
                        

                            <li className={`${["nft-explore", "nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/crm"> CRM </Link>
                         
                            </li>

                            <li className={`${["shop-grid", "shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/hrm"> HRM </Link>
                             
                            </li>
                            <li className={`${["photography-about", "photography-portfolio"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/reporting">Reporting </Link>
                             
                            </li>
                          
                        
                    
                        </ul> */}
                    </li>
                    
               



                    
               


                    {/* <li className={`${["index-saas", "index-classic-saas","index-modern-saas", "index-apps","index-classic-app","index-ai","index-smartwatch","index-marketing","index-seo","index-software","index-payment","index-charity","index-it-solution","index-it-solution-two","index-digital-agency","index-restaurent","index-hosting","index-nft","index-hotel","index-travel","index-cafe","index-gym","index-yoga","index-spa","index-job","index-startup","index-business","index-corporate","index-corporate-two","index-real-estate","index-consulting","index-insurance","index-construction","index-law","index-video","index-personal","index-portfolio","index-photography","index-studio","index-coworking","index-course","index-event","index-podcast","index-hospital","index-phone-number","index-forums","index-shop","index-crypto","index-landing-one","index-landing-two","index-landing-three","index-landing-four","index-service","index-food-blog","index-blog","index-furniture","index-landing-five","index-life-coach","index-landing-six"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`} >
                        <Link to="/solution" >Solution</Link> */}
                        {/* <span className="menu-arrow"></span> */}

                        {/* <ul className="submenu megamenu">
                            <li>
                                <ul>
                                    <li className={manu === "index-saas" || "" ? "active" : ""}><Link to="/index-saas" className="sub-menu-item">Saas</Link></li>
                                    <li className={manu === "index-classic-saas" || "" ? "active" : ""}><Link to="/" className="sub-menu-item">Classic Saas </Link></li>
                                    <li className={manu === "index-modern-saas" || "" ? "active" : ""}><Link to="/index-modern-saas" className="sub-menu-item">Modern Saas </Link></li>
                                    <li className={manu === "index-apps" || "" ? "active" : ""}><Link to="/index-apps" className="sub-menu-item">Application</Link></li>
                                    <li className={manu === "index-classic-app" || "" ? "active" : ""}><Link to="/index-classic-app" className="sub-menu-item">Classic App </Link></l
                                    <li className={manu === "index-smartwatch" || "" ? "active" : ""}><Link to="/index-smartwatch" className="sub-menu-item">Smartwatch</Link></li>
                                    <li className={manu === "index-marketing" || "" ? "active" : ""}><Link to="/index-marketing" className="sub-menu-item">Marketing</Link></li>
                                    <li className={manu === "index-seo" || "" ? "active" : ""}><Link to="/index-seo" className="sub-menu-item">SEO Agency </Link></li>
                                    <li className={manu === "index-software" || "" ? "active" : ""}><Link to="/index-software" className="sub-menu-item">Software </Link></li>
                                    <li className={manu === "index-payment" || "" ? "active" : ""}><Link to="/index-payment" className="sub-menu-item">Payments</Link></li>
                                    <li className={manu === "index-charity" || "" ? "active" : ""}><Link to="/index-charity" className="sub-menu-item">Charity </Link></li>
                                </ul>
                            </li>

                            <li>
                                <ul>
                                    <li className={manu === "index-it-solution" || "" ? "active" : ""}><Link to="/index-it-solution" className="sub-menu-item">IT Solution</Link></li>
                                    <li className={manu === "index-it-solution-two" || "" ? "active" : ""}><Link to="/index-it-solution-two" className="sub-menu-item">It Solution Two </Link></li>
                                    <li className={manu === "index-digital-agency" || "" ? "active" : ""}><Link to="/index-digital-agency" className="sub-menu-item">Digital Agency</Link></li>
                                    <li className={manu === "index-restaurent" || "" ? "active" : ""}><Link to="/index-restaurent" className="sub-menu-item">Restaurent</Link></li>
                                    <li className={manu === "index-hosting" || "" ? "active" : ""}><Link to="/index-hosting" className="sub-menu-item">Hosting</Link></li>
                                    <li className={manu === "index-nft" || "" ? "active" : ""}><Link to="/index-nft" className="sub-menu-item">NFT Marketplace <span className="bg-red-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Full</span></Link></li>
                                    <li className={manu === "index-hotel" || "" ? "active" : ""}><Link to="/index-hotel" className="sub-menu-item">Hotel & Resort</Link></li>
                                    <li className={manu === "index-travel" || "" ? "active" : ""}><Link to="/index-travel" className="sub-menu-item">Travels</Link></li>
                                    <li className={manu === "index-cafe" || "" ? "active" : ""}><Link to="/index-cafe" className="sub-menu-item">Cafe <span className="bg-black dark:bg-slate-50 inline-block text-white dark:text-slate-900 text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Dark</span></Link></li>
                                    <li className={manu === "index-gym" || "" ? "active" : ""}><Link to="/index-gym" className="sub-menu-item">Gym <span className="bg-black dark:bg-slate-50 inline-block text-white dark:text-slate-900 text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Dark</span></Link></li>
                                    <li className={manu === "index-yoga" || "" ? "active" : ""}><Link to="/index-yoga" className="sub-menu-item">Yoga </Link></li>
                                    <li className={manu === "index-spa" || "" ? "active" : ""}><Link to="/index-spa" className="sub-menu-item">Spa & Salon </Link></li>
                                </ul>
                            </li>

                            <li>
                                <ul>
                                    <li className={manu === "index-job" || "" ? "active" : ""}><Link to="/index-job" className="sub-menu-item">Job<span className="bg-red-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Full</span></Link></li>
                                    <li className={manu === "index-startup" || "" ? "active" : ""}><Link to="/index-startup" className="sub-menu-item">Startup</Link></li>
                                    <li className={manu === "index-business" || "" ? "active" : ""}><Link to="/index-business" className="sub-menu-item">Business</Link></li>
                                    <li className={manu === "index-corporate" || "" ? "active" : ""}><Link to="/index-corporate" className="sub-menu-item">Corporate</Link></li>
                                    <li className={manu === "index-corporate-two" || "" ? "active" : ""}><Link to="/index-corporate-two" className="sub-menu-item">Corporate Two </Link></li>
                                    <li className={manu === "index-real-estate" || "" ? "active" : ""}><Link to="/index-real-estate" className="sub-menu-item">Real Estate <span className="bg-red-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Full</span></Link></li>
                                    <li className={manu === "index-consulting" || "" ? "active" : ""}><Link to="/index-consulting" className="sub-menu-item">Consulting </Link></li>
                                    <li className={manu === "index-life-coach" || "" ? "active" : ""}><Link to="/index-life-coach" className="sub-menu-item">Life Coach <span className="bg-yellow-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">New</span></Link></li>
                                    <li className={manu === "index-insurance" || "" ? "active" : ""}><Link to="/index-insurance" className="sub-menu-item">Insurance </Link></li>
                                    <li className={manu === "index-construction" || "" ? "active" : ""}><Link to="/index-construction" className="sub-menu-item">Construction </Link></li>
                                    <li className={manu === "index-law" || "" ? "active" : ""}><Link to="/index-law" className="sub-menu-item">Law Firm </Link></li>
                                    <li className={manu === "index-video" || "" ? "active" : ""}><Link to="/index-video" className="sub-menu-item">Video </Link></li>
                                    <li className={manu === "index-christmas" || "" ? "active" : ""}><Link to="/index-christmas" className="sub-menu-item">Christmas </Link></li>
                                </ul>
                            </li>

                            <li>
                                <ul>
                                    <li className={manu === "index-personal" || "" ? "active" : ""}><Link to="/index-personal" className="sub-menu-item">Personal</Link></li>
                                    <li className={manu === "index-portfolio" || "" ? "active" : ""}><Link to="/index-portfolio" className="sub-menu-item">Portfolio <span className="bg-red-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Full</span></Link></li>
                                    <li className={manu === "index-photography" || "" ? "active" : ""}><Link to="/index-photography" className="sub-menu-item">Photography <span className="bg-black dark:bg-slate-50 inline-block text-white dark:text-slate-900 text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Dark</span></Link></li>
                                    <li className={manu === "index-studio" || "" ? "active" : ""}><Link to="/index-studio" className="sub-menu-item">Studio</Link></li>
                                    <li className={manu === "index-coworking" || "" ? "active" : ""}><Link to="/index-coworking" className="sub-menu-item">Co-Woriking</Link></li>
                                    <li className={manu === "index-classic-business" || "" ? "active" : ""}><Link to="/index-classic-business" className="sub-menu-item">Classic Business </Link></li>
                                    <li className={manu === "index-course" || "" ? "active" : ""}><Link to="/index-course" className="sub-menu-item">Online Courses </Link></li>
                                    <li className={manu === "index-event" || "" ? "active" : ""}><Link to="/index-event" className="sub-menu-item">Event / Conference </Link></li>
                                    <li className={manu === "index-podcast" || "" ? "active" : ""}><Link to="/index-podcast" className="sub-menu-item">Podcasts</Link></li>
                                    <li className={manu === "index-hospital" || "" ? "active" : ""}><Link to="/index-hospital" className="sub-menu-item">Hospital</Link></li>
                                    <li className={manu === "index-phone-number" || "" ? "active" : ""}><Link to="/index-phone-number" className="sub-menu-item">Phone Number</Link></li>
                                    <li className={manu === "index-forums" || "" ? "active" : ""}><Link to="/index-forums" className="sub-menu-item">Forums </Link></li>
                                </ul>
                            </li>

                            <li>
                                <ul>
                                    <li className={manu === "index-shop" || "" ? "active" : ""}><Link to="/index-shop" className="sub-menu-item">Shop / eCommerce <span className="bg-red-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Full</span> </Link></li>
                                    <li className={manu === "index-crypto" || "" ? "active" : ""}><Link to="/index-crypto" className="sub-menu-item">Cryptocurrency  <span className="bg-black dark:bg-slate-50 inline-block text-white dark:text-slate-900 text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Dark</span></Link></li>
                                    <li className={manu === "index-landing-one" || "" ? "active" : ""}><Link to="/index-landing-one" className="sub-menu-item">Landing One</Link></li>
                                    <li className={manu === "index-landing-two" || "" ? "active" : ""}><Link to="/index-landing-two" className="sub-menu-item">Landing Two</Link></li>
                                    <li className={manu === "index-landing-three" || "" ? "active" : ""}><Link to="/index-landing-three" className="sub-menu-item">Landing Three</Link></li>
                                    <li className={manu === "index-landing-four" || "" ? "active" : ""}><Link to="/index-landing-four" className="sub-menu-item">Landing Four</Link></li>
                                    <li className={manu === "index-landing-six" || "" ? "active" : ""}><Link to="/index-landing-six" className="sub-menu-item">Landing Six <span className="bg-yellow-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">New</span></Link></li>
                                    <li className={manu === "index-service" || "" ? "active" : ""}><Link to="/index-service" className="sub-menu-item">Service Provider</Link></li>
                                    <li className={manu === "index-food-blog" || "" ? "active" : ""}><Link to="/index-food-blog" className="sub-menu-item">Food Blog </Link></li>
                                    <li className={manu === "index-blog" || "" ? "active" : ""}><Link to="/index-blog" className="sub-menu-item">Blog </Link></li>
                                    <li className={manu === "index-furniture" || "" ? "active" : ""}><Link to="/index-furniture" className="sub-menu-item">Furniture </Link></li>
                                    <li className={manu === "index-landing-five" || "" ? "active" : ""}><Link to="/index-landing-five" className="sub-menu-item">Landing Five <span className="bg-green-600 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Onepage</span></Link></li>
                                </ul>
                            </li>
                        </ul> */}
                    {/* </li> */}

                    {/* <li className={`${["page-aboutus", "page-services","page-team", "page-pricing","page-testimonial","user-profile","user-billing","user-payment","user-invoice","user-notification","user-setting","property-listing","property-detail","course-listing","course-detail","nft-explore","nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail","food-recipe","shop-grid","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","photography-about","photography-portfolio","page-job-grid","page-job-detail","page-job-apply","page-job-post","page-job-career","page-job-candidates","page-job-candidate-detail","page-job-companies","page-Job-company-detail","forums-topic","forums-comments","helpcenter-overview","helpcenter-faqs","helpcenter-guides","helpcenter-support","blog","blog-sidebar","blog-detail","blog-standard-post","blog-slider-post","blog-gallery-post","blog-youtube-post","blog-vimeo-post","blog-audio-post","blog-blockquote-post","blog-left-sidebar-post","email-confirmation","email-password-reset","email-alert","email-invoice","auth-login","auth-signup","auth-re-password","auth-lock-screen","page-terms","page-privacy","page-comingsoon","page-maintenance","page-error","page-thankyou","contact-detail","contact-one","contact-two"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`}> */}
                        {/* <Link to="/#">Pricing </Link> */}
                        {/* <span className="menu-arrow"></span> */}
                        
                    {/* </li> */}

                    {/* <li className={`${["portfolio-modern-two", "portfolio-modern-three","portfolio-modern-four","portfolio-modern-five","portfolio-modern-six","portfolio-classic-two","portfolio-classic-three","portfolio-classic-four","portfolio-classic-five","portfolio-classic-six","portfolio-creative-two","portfolio-creative-three","portfolio-creative-four","portfolio-creative-five","portfolio-creative-six","portfolio-masonry-two","portfolio-masonry-three","portfolio-masonry-four","portfolio-masonry-five","portfolio-masonry-six","portfolio-detail-one","portfolio-detail-two","portfolio-detail-three",'portfolio-detail-four'].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`} >
                        <Link to="">Outlet Types </Link>
                        <span className="menu-arrow"></span>
                        <ul className="submenu megamenu">
                            <li>
                                <ul>
                                    <li className={manu === "portfolio-modern-two" || "" ? "active" : ""}><Link to="/departmental" className="sub-menu-item">Departmental Store</Link></li>
                                    <li className={manu === "portfolio-modern-three" || "" ? "active" : ""}><Link to="/supermarket" className="sub-menu-item">Supermarket</Link></li>
                                    <li className={manu === "portfolio-modern-four" || "" ? "active" : ""}><Link to="/grocery" className="sub-menu-item">Grocery Store</Link></li>
                                    <li className={manu === "portfolio-modern-five" || "" ? "active" : ""}><Link to="/toy" className="sub-menu-item">Toy Store</Link></li>
                                    <li className={manu === "portfolio-modern-six" || "" ? "active" : ""}><Link to="/stationery" className="sub-menu-item">Stationery Store</Link></li>
                                    <li className={manu === "portfolio-classic-two" || "" ? "active" : ""}><Link to="/hardware_store" className="sub-menu-item">Hardware Store</Link></li>

                                </ul>
                            </li>

                            <li>
                                <ul>
                                    <li className={manu === "portfolio-classic-three" || "" ? "active" : ""}><Link to="/sanitary" className="sub-menu-item">Sanitary Store</Link></li>
                                    <li className={manu === "portfolio-classic-four" || "" ? "active" : ""}><Link to="/liquor" className="sub-menu-item">Liquor Store</Link></li>
                                    <li className={manu === "portfolio-classic-five" || "" ? "active" : ""}><Link to="/retail" className="sub-menu-item">Retail Outlet</Link></li>
                                    <li className={manu === "portfolio-classic-six" || "" ? "active" : ""}><Link to="/garment" className="sub-menu-item">Garment Store</Link></li>
                                    <li className={manu === "portfolio-creative-two" || "" ? "active" : ""}><Link to="/footwear" className="sub-menu-item">Footwear</Link></li>
                                    <li className={manu === "portfolio-creative-three" || "" ? "active" : ""}><Link to="/fashion_store" className="sub-menu-item">Fashion Store</Link></li>

                                </ul>
                            </li>

                            <li>
                                <ul>
                                    <li className={manu === "portfolio-creative-four" || "" ? "active" : ""}><Link to="/pharmacy" className="sub-menu-item">Pharmacy</Link></li>
                                    <li className={manu === "portfolio-creative-five" || "" ? "active" : ""}><Link to="/jewellery" className="sub-menu-item">Jewellery Store</Link></li>
                                    <li className={manu === "portfolio-creative-six" || "" ? "active" : ""}><Link to="/industries_pos" className="sub-menu-item">POS</Link></li>
                                    <li className={manu === "portfolio-masonry-two" || "" ? "active" : ""}><Link to="/automobile_store" className="sub-menu-item">Automobile Store</Link></li>
                                    <li className={manu === "portfolio-masonry-three" || "" ? "active" : ""}><Link to="/book_store" className="sub-menu-item">Book Store</Link></li>
                                </ul>
                            </li>

                            <li>
                                <ul>
                                   
                                    <li className={manu === "portfolio-masonry-four" || "" ? "active" : ""}><Link to="/electrical" className="sub-menu-item">Electrical Store</Link></li>
                                    <li className={manu === "portfolio-masonry-five" || "" ? "active" : ""}><Link to="/electronics" className="sub-menu-item">Electronics Store</Link></li>
                                    <li className={manu === "portfolio-masonry-six" || "" ? "active" : ""}><Link to="/services_center" className="sub-menu-item">Service Center</Link></li>
                                    <li className={manu === "portfolio-detail-one" || "" ? "active" : ""}><Link to="/salon" className="sub-menu-item">Salon/Spa</Link></li>
                                    <li className={manu === "portfolio-detail-two" || "" ? "active" : ""}><Link to="/mobile_store" className="sub-menu-item">Mobile Store</Link></li>
                                </ul>
                            </li>

                            <li>
                                <ul>
                                   
                                    <li className={manu === "portfolio-detail-three" || "" ? "active" : ""}><Link to="/computer_store" className="sub-menu-item">Computer Store</Link></li>
                                    <li className={manu === "portfolio-detail-four" || "" ? "active" : ""}><Link to="/repair_shop" className="sub-menu-item">Repair Shop</Link></li>
                                    <li className={manu === "portfolio-detail-four" || "" ? "active" : ""}><Link to="/manufacturing" className="sub-menu-item">Manufacturing</Link></li>
                                    <li className={manu === "portfolio-detail-four" || "" ? "active" : ""}><Link to="/restaurant" className="sub-menu-item">Restaurant</Link></li>
                                    <li className={manu === "portfolio-detail-four" || "" ? "active" : ""}><Link to="/furniture" className="sub-menu-item">Furniture Store</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </li> */}

                    <li className={`${["page-aboutus", "page-services","page-team", "page-pricing","page-testimonial","user-profile","user-billing","user-payment","user-invoice","user-notification","user-setting","property-listing","property-detail","course-listing","course-detail","nft-explore","nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail","food-recipe","shop-grid","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","photography-about","photography-portfolio","page-job-grid","page-job-detail","page-job-apply","page-job-post","page-job-career","page-job-candidates","page-job-candidate-detail","page-job-companies","page-Job-company-detail","forums-topic","forums-comments","helpcenter-overview","helpcenter-faqs","helpcenter-guides","helpcenter-support","blog","blog-sidebar","blog-detail","blog-standard-post","blog-slider-post","blog-gallery-post","blog-youtube-post","blog-vimeo-post","blog-audio-post","blog-blockquote-post","blog-left-sidebar-post","email-confirmation","email-password-reset","email-alert","email-invoice","auth-login","auth-signup","auth-re-password","auth-lock-screen","page-terms","page-privacy","page-comingsoon","page-maintenance","page-error","page-thankyou","contact-detail","contact-one","contact-two"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`}>
                        <Link to=""> Resources </Link>
                        {/* <span className="menu-arrow"></span> */}
                        {/* <ul className="submenu">
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/restaurant"> Restaurant </Link>
                              
                            </li>
                            <li className={`${["user-profile", "user-billing","user-payment", "user-invoice", "user-social","user-notification","user-setting"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/bar_lounge"> Bar & lounge</Link>
                              
                            </li>
                     
                        

                            <li className={`${["nft-explore", "nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/qsr"> QSR </Link>
                         
                            </li>

                            <li className={`${["shop-grid", "shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/pizza"> Pizza </Link>
                             
                            </li>
                            <li className={`${["photography-about", "photography-portfolio"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/fine_dining">Fine Dining </Link></li>
                            <li className={`${["photography-about", "photography-portfolio"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/hotel">Hotel</Link></li>
                            <li className={`${["photography-about", "photography-portfolio"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/cafe_bakery">Cafe & bakery</Link></li>
                            <li className={`${["photography-about", "photography-portfolio"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/icecream">Icecream Parlour </Link></li>
                            <li className={`${["photography-about", "photography-portfolio"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/food_truck">Food Truck </Link></li>
                        </ul> */}
                    </li>

                    {/* <li className={`${["ui-components", "documentation","changelog"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}>
                        <Link to="">Hardware</Link>
                         <span className="menu-arrow"></span> 
                        <ul className="submenu">
                            <li className={manu === "ui-components" || "" ? "active" : ""}><Link to="/handy_pos" className="sub-menu-item">HANDY POS </Link></li>
                            <li className={manu === "documentation" || "" ? "active" : ""}><Link to="/label_printer" className="sub-menu-item"> PRINTERS</Link></li>
                            <li className={manu === "changelog" || "" ? "active" : ""}><Link to="/android_pos" className="sub-menu-item">ANDROID POS</Link></li>
                            <li className={manu === "changelog" || "" ? "active" : ""}><Link to="/windows_pos" className="sub-menu-item">WINDOWS POS</Link></li>
                           
                            <li className={manu === "changelog" || "" ? "active" : ""}><Link to="/barcode_scanner" className="sub-menu-item">BARCODE SCANNER</Link></li>
                            <li className={manu === "changelog" || "" ? "active" : ""}><Link to="#" className="sub-menu-item">OTHER ACCESSORIES</Link></li>
                       
                        </ul> 
                    </li> */}

                    

                    {/* <li className={`${["page-aboutus", "page-services","page-team", "page-pricing","page-testimonial","user-profile","user-billing","user-payment","user-invoice","user-notification","user-setting","property-listing","property-detail","course-listing","course-detail","nft-explore","nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail","food-recipe","shop-grid","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","photography-about","photography-portfolio","page-job-grid","page-job-detail","page-job-apply","page-job-post","page-job-career","page-job-candidates","page-job-candidate-detail","page-job-companies","page-Job-company-detail","forums-topic","forums-comments","helpcenter-overview","helpcenter-faqs","helpcenter-guides","helpcenter-support","blog","blog-sidebar","blog-detail","blog-standard-post","blog-slider-post","blog-gallery-post","blog-youtube-post","blog-vimeo-post","blog-audio-post","blog-blockquote-post","blog-left-sidebar-post","email-confirmation","email-password-reset","email-alert","email-invoice","auth-login","auth-signup","auth-re-password","auth-lock-screen","page-terms","page-privacy","page-comingsoon","page-maintenance","page-error","page-thankyou","contact-detail","contact-one","contact-two"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`}>
                        <Link to="">Resources</Link>
                        <span className="menu-arrow"></span>
                        <ul className="submenu">
                            <li className={`${["page-aboutus", "page-services","page-team", "page-pricing", "page-testimonial"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="#"> Blogs  </Link>
                              
                            </li>
                            <li className={`${["user-profile", "user-billing","user-payment", "user-invoice", "user-social","user-notification","user-setting"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/contact-one"> Contact Us</Link>
                              
                            </li>
                            
                    
                        </ul>
                    </li> */}


                    {/* <li className={manu === "contact-one" || "" ? "active" : ""}><Link to="/contact-one" className="sub-menu-item">Contact</Link></li> */}

                    
                    {/* <div className='header-button'>
                        <Link to="/get-started"  className="py-2 px-5  inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Login </Link>
                        <Link to="/get-started"  className="py-2 px-5 ms-2 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Support </Link>
                    </div> */}
                </ul>

            </div>
        </div >

        

      
        </nav >

        
    )
    
}
