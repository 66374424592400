import React from 'react'
import { Link } from 'react-router-dom';
// import Topbar from '../../component/Topbar'

import logo_light from '../assets/images/visitersqure.png';

export default function Topbar() {
    return (
        <div className='topbar '>
        <div className='grid md:grid-cols-2 grid-cols-1 gap-[30px]'>
            <div className='col-md-4'>
                {/* <Link to="/index"><img src={logo_light} className=" width30" alt=""/></Link> */}
            </div>

            <div className='col-md-8 d-flex justify-content-end align-items-center'>
                <div>
                    <ul className="list-none footer-list d-flex justify-content-end">
                            <li><Link to='tel:8851286001' className=" hover:text-gray-400 duration-500 ease-in-out inline-flex items-center mr-4 text-white"><i class="fa fa-phone mr-2 text-white" aria-hidden="true"></i>Sales : 08069640643</Link></li>
                            {/* <li><Link to='mailto:connect@edukrypt.com' className=" hover:text-gray-400 duration-500 ease-in-out inline-flex items-center text-white"><i class="fa fa-envelope-o mr-2 text-white" aria-hidden="true"></i>                                connect@edukrypt.com</Link></li> */}
                    </ul>
                </div>
            </div>
        </div>
    </div>
    )
}
