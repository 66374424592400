import React,{useEffect,useState} from 'react';
import { Link } from 'react-router-dom';

// import Startup_SVG from '../../assets/images/illustrator/Startup_SVG.svg';
// import SEO_SVG from '../../assets/images/illustrator/SEO_SVG.svg';
// import Encryption from '../../assets/images/about/encryption.png';
// import AppIcon from '../../assets/images/client/appIcon.png';
// import encryptionIcon from '../../assets/images/client/encryptionIcon.png';
// import Mobile_notification_SVG from '../../assets/images/illustrator/Mobile_notification_SVG.svg';
// import Blog1 from '../../assets/images/blog/bg1.jpg';
// import LaptopScreen from '../../assets/images/client/laptopScreen.png';

// import TinySlider from 'tiny-slider-react';
import '../../../node_modules/tiny-slider/dist/tiny-slider.css'

import Navbar from '../../component/Navbar/navbar';
import Footer from '../../component/Footer/footer';
import CookieModal from '../../component/cookieModal';
// import CompanyLogo from '../../component/companyLogo';
// import GetInTuch from '../../component/getInTuch';
// import BlogUserDetail from '../../component/blogUserDetail';
// import { blogData } from '../../data/data';

// import * as Icon from 'react-feather';
import {FaRegEnvelope} from '../../assets/icons/icons'
// import { feedback } from '../../data/data';

// import { startupAbout, startupServices } from '../../data/dataTwo';
import { accordionData } from '../../data/dataTwo';

export default function Product() {

  let [activeIndex, setActiveIndex] = useState(0);

  let toggleAccordion = (index) => {
      if (activeIndex === index) {
          setActiveIndex(0);
      } else {
          setActiveIndex(index);
      }
  }
  
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add('light');
    
  }, []);



  return (
    <div >

      <Navbar />

      <section className="relative table w-full py-36 ">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
            <div className="md:col-span-7">
              <div className="me-6">
              {/* <h6 class="mb-4 text-base font-medium text-indigo-600">Mobile + TV Apps</h6> */}
                <h4 className="font-semibold lg:leading-normal heading leading-normal text-4xl lg:text-5xl mb-5 text-black dark:text-white"> Your Gateway to Knowledge <span className="text-indigo-600">and Growth</span></h4>
                <p className="text-slate-400 text-lg max-w-xl text-justify">Are you ready to unlock a world of knowledge from the comfort of your own home? Look no further. Dive into our curated selection of courses designed to enhance your skills</p>

                <div className="mt-6">
                  <Link to="/contact-one" className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaRegEnvelope  className="me-2 text-sm "/> Get Started</Link>
                  <Link to="/documentation" className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-indigo-600 border-indigo-600 text-indigo-600 hover:text-white rounded-md mt-2"> Book Demo</Link>
                </div>
              </div>
            </div>

            <div className="md:col-span-5">
              <img src='images/product_banner.png' alt="" />
            </div>
          </div>
        </div>
      </section>

    

      


          <section className="relative py-16 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                      <div className=' text-center'>
                        {/* <h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">MULTIPLE LEARNING PRODUCTS</h6> */}
                        <h3 class="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0">Why Choose VideoKrypt's Online Course Platform?</h3>
                        <p class="text-slate-400  mt-2 ">Explore, Learn, and Thrive with our innovative online learning platform.</p>
                      </div>
                    

                      <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] mt-7 pt-7">
                    <div class="flex">
                        {/* <div class="flex align-middle justify-center items-center min-w-[56px] h-[56px] bg-indigo-600/5 border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-xl shadow-sm dark:shadow-gray-800">
                            <i class="uil uil-water"></i>
                        </div> */}

                        <div class="content ms-6">
                            <a href="" class="text-lg font-medium hover:text-indigo-600">Diverse Course Selection</a>
                            <div className='border-t mt-3'></div>
                            <p class="text-slate-400 mt-3 text-justify">Explore a wide range of topics spanning from business and technology to arts and personal development. </p>
                        </div>
                    </div>
                    {/* <!--end feature content--> */}

                    <div class="flex">
                        {/* <div class="flex align-middle justify-center items-center min-w-[56px] h-[56px] bg-indigo-600/5 border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-xl shadow-sm dark:shadow-gray-800">
                            <i class="uil uil-dropbox"></i>
                        </div> */}

                        <div class="content ms-6">
                            <a href="" class="text-lg font-medium hover:text-indigo-600">Flexible Learning</a>
                            <div className='border-t mt-3'></div>

                            <p class="text-slate-400 mt-3 text-justify">With VideoKrypt, learning fits seamlessly into your schedule. Access courses anytime, anywhere, and at your own pace. </p>
                        </div>
                    </div>
                    {/* <!--end feature content--> */}

                    <div class="flex">
                        {/* <div class="flex align-middle justify-center items-center min-w-[56px] h-[56px] bg-indigo-600/5 border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-xl shadow-sm dark:shadow-gray-800">
                            <i class="uil uil-focus-target"></i>
                        </div> */}

                        <div class="content ms-6">
                            <a href="" class="text-lg font-medium hover:text-indigo-600">Interactive Experience</a>
                            <div className='border-t mt-3'></div>

                            <p class="text-slate-400 mt-3 text-justify">Engage with course material through interactive quizzes, assignments, and discussions</p>
                        </div>
                    </div>
                    {/* <!--end feature content--> */}
                </div>
                {/* <!--end grid--> */}
                </div>
            </section>
      
      
      <section className="relative py-16 ">
        <div className="container relative">
            <div className='text-center'>
              {/* <h6 class="text-indigo-600 text-sm font-bold uppercase mb-2">MULTIPLE LEARNING PRODUCTS</h6> */}
              <h3 class="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0">Expand Your Horizons with These Key Features</h3>
              <p class="text-slate-400  mt-2">Customers with their own apps see a 2x increase in monthly recurringrevenue because <br/>audiences love having their membership on their phones.</p>
            </div>
            <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]">
                    
                <div className="lg:col-span-7">
                  <div className="lg:ms-10">
                    
                    {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                    <h3 className="mb-4 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Personalized Recommendations</h3>
                    <p className="text-slate-400 max-w-xl text-justify">Our advanced recommendation system suggests courses tailored to your interests, experience level, and learning goals. Say goodbye to endless searching and let VideoKrypt guide you to the perfect learning path.</p>

                    {/* <div className="mt-4">
                      <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl"/></Link>
                    </div> */}
                  </div>
                </div>
              <div className="lg:col-span-5">
                <img src='images/product_img1.png' alt="" />
              </div>
              
            </div>
            <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
              <div className="lg:col-span-5">
                <img src="images/product_img2.png" alt=""  className='width90'/>
              </div>
              <div className="lg:col-span-7">
                  <div className="lg:ms-10">
                    
                    {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                    <h3 className="mb-4 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Live Q&A Sessions</h3>
                    <p className="text-slate-400 max-w-xl text-justify">Interact directly with instructors during live Q&A sessions to clarify concepts, delve deeper into topics, and gain valuable insights. Take advantage of this opportunity to connect with experts and enhance your understanding in real-time.</p>

                    {/* <div className="mt-4">
                      <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl"/></Link>
                    </div> */}
                  </div>
                </div>
              
            </div>

            <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
              <div className="lg:col-span-7">
                  <div className="lg:ms-10">
                    
                    {/* <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6> */}
                    <h3 className="mb-4 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Community Support</h3>
                    <p className="text-slate-400 max-w-xl text-justify">Join a vibrant community of learners who share your passion for growth and knowledge. Exchange ideas, network with peers, and collaborate on projects to enrich your learning experience. At VideoKrypt, you're never alone on your learning journey.</p>

                    {/* <div className="mt-4">
                      <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl"/></Link>
                    </div> */}
                  </div>
                </div>
              <div className="lg:col-span-5 d-flex justify-content-end">
                <img src="images/product_img3.jpg" alt="" className='width70 m-end'/>
              </div>
              
              
            </div>

            <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center  gap-[30px]">
              <div className="lg:col-span-5">
                <img src="images/product_img4.png" alt="" />
              </div>
              <div className="lg:col-span-7">
                  <div className="lg:ms-10">
                    
                    <h6 className="mb-4 text-base font-medium text-indigo-600">Edukrypt Encryption</h6>
                    <h3 className="mb-4 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Lifetime Access</h3>
                    <p className="text-slate-400 max-w-xl text-justify">Once enrolled in a course, enjoy lifetime access to course materials and updates. Whether you want to revisit content for reinforcement or stay updated on the latest industry trends, VideoKrypt ensures you have access to your learning resources whenever you need them.</p>

                    {/* <div className="mt-4">
                      <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl"/></Link>
                    </div> */}
                  </div>
                </div>
              
            </div>
        </div>
      </section>


      {/* faqs Start here */}
      <div className="container relative pt-5">
        <div className="grid grid-cols-1 pb-5 text-center">
            <h3 className="mb-3 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">FAQs</h3>

            <p className="text-slate-400 max-w-xl mx-auto">Whether you're a beginner or an expert, VideoKrypt's Online Course Platform is your gateway to endless possibilities. </p>
        </div>

        <div className="relative grid md:grid-cols-12 grid-cols-1 items-center  gap-[30px]">
            <div className="md:col-span-6">
                <div className="relative">
                    <div className="relative rounded-xl overflow-hidden ">
                        {/* <div className="w-full py-72 bg-slate-400 bg-no-repeat bg-top bg-cover jarallax" data-jarallax data-speed="0.5"> */}
                          <img src='images/13330330_Feb-Business_9.svg'alt="" className=''/>
                        {/* </div> */}
                    </div>
                </div>
            </div>

            <div className="md:col-span-6">
                <div id="accordion-collapse">
                    {accordionData.slice(0,4).map((item, index) => (
                        <div key={index} className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                            <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                <button type="button" onClick={() => toggleAccordion(index)} className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeIndex === index ? 'bg-gray-50 dark:bg-slate-800 text-indigo-600' : ''}`} data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                    <span>How does it work?</span>
                                    <svg data-accordion-icon className={`${activeIndex === index ? "rotate-180" : "rotate-270" } size-4 shrink-01`}  fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                    </svg>
                                </button>
                            </h2>
                            {activeIndex === index && (
                                <div>
                                    <div className="p-5">
                                        <p className="text-slate-400 dark:text-gray-400">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p>
                                    </div>
                                </div>
                            )}
                        </div>

                    ))}
                </div>
            </div>
        </div>
      </div>

      <section className='py-16 w-full table relative bg-indigo-600 mb-12'>
        <div className='container'>
        <h3 class="mb-4 md:text-4xl text-3xl lg:leading-normal leading-normal text-white font-semibold text-center">Discover Your Next Learning Adventure</h3>
          <p class="text-white/80 max-w-xl mx-auto lg:ms-auto  text-center">Browse our catalog of courses and start learning today. Unlock your potential with us!</p>
          <div class="mt-6 d-flex justify-content-center" style={{justifyContent: "center",display:'flex'}}>
            <div className="mt-4">
                  <Link to="#" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-slate-100 hover:bg-slate-200 border-slate-100 hover:border-slate-200 text-indigo-600 rounded-md me-2 mt-2">Book Demo</Link>
              </div>
            </div>
        </div>
      </section>

      

      

    

      <Footer />

      <CookieModal />

     
    </div>
  )
}
